<div class="inner_content_block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12">
        <div class="title_block">
          <h1 class="float-start">Organization Dashboard</h1>
        
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div class="dashboard_block">
          <div class="row">
            <div class="col-12 col-xl-4 col-lg-4 col-md-6 d-flex">
              <div class="number_block_dashboard">
             
                <a>
                  <span class="text">{{connectedOn}}</span>Connected Since
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
            <div class="col-12 col-xl-2 col-lg-2 col-md-6 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/division/list">
                  <span>{{divisionCount}}</span>Divisions
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
        
            <div class="col-12 col-xl-3 col-lg-3 col-md-6 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/users">
                  <span>{{userCount}}</span>Users
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>

            <div class="col-12 col-xl-3 col-lg-3 col-md-6 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/cohort">
                  <span>{{cohortCount}}</span>Cohorts
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <label class="chart_lbl">Scroll right to view the entire chart</label>
              <div class="dashboard_box pb-0 mb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Users by Divisions</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.userChart">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-3">Below pie chart shows the distribution of users by Division based on the total number of
                  users in each Division.</p>
                <div class="clearfix"></div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.userChart">
                  <div class="height_block">
                    Data not available
                  </div>
                </div>
                <div class="responsive_chart">
                  <div id="user_chart" class="user_chart_height"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line res_mt20">
              <div class="dashboard_box pb-0 mb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Recently Connected Users</h5>
                  <a *ngIf="recentlyConnectedUsers.length > 0" class="float-end view_all no_border"
                    routerLink="/manage/users"><i class="fa fa-eye"></i> View
                    All</a>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.recentUser">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-3">Below is the list of recently connected users either by bulk upload or by connecting
                  them individually.</p>
                <div class="clearfix"></div>
                <div class="table-responsive pb-3">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th class="name_width">Name</th>
                        <th class="connected_width">Connected on</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of recentlyConnectedUsers; let i=index;">
                        <td>{{user.user_details}}</td>
                        <td>{{user.created_at | date:"MMMM d, yyyy hh:mm a" : 'UTC'}}</td>
                      </tr>
                      <tr *ngIf="recentlyConnectedUsers.length == 0">
                        <td colspan="2" style="text-align: center;">Data not available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>