import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var $, Swal: any;
import { CookieService } from 'ngx-cookie-service';
import { IntermediatePageService } from '../services/intermediate-page.service';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
})
export class IntermediatePageComponent implements OnInit {
  constructor(
    public globals: Globals,
    private router: Router,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private CookieService: CookieService,
    private IntermediatePageService: IntermediatePageService
  ) {}

  ngOnInit(): void {
    this.globals.isLoading = true;

    let u = this.router.url.split('/')[3];
    if (u) {
      var ur = u.split('?');
      if (ur[0] == 'organization-registration') {
        localStorage.setItem("registration", 'yes');
      }
    }


    let token = this.route.snapshot.queryParams.IfmaToken
      ?this.route.snapshot.queryParams.IfmaToken
      :null;
    this.CookieService.set(
      'amsn_token',
      token,
      365,
      '/',
      this.globals.CookieDomainUrl
    );

    this.AuthService.checkAMSNLogin({
      usertoken: token,
      ip_address: null,
      user_agent: null,
    }).then(
      (data) => {

        this.AuthService.getAuthUser().then(
          (data) => {
            
         
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          }
        );
      },
      (error) => {
        this.globals.isLoading = false;
        if (error.status == 400) {
          this.globals.sweetAlert(
            'warning',
            'Deactive',
            'Your account is deactive. so you cannot login.'
          );
        } else if (error.status == 401) {
          this.globals.sweetAlert(
            'warning',
            'Invalid Login Credentials',
            'Either email or password is incorrect.'
          );
        }else if(error.status == 403){
          // for deactivated cohort login
        Swal.fire({
          title: 'Organization is disconnected!',
          text: 'To reconnect the organization. Please contact the IFMA admin.',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = this.globals.amsnLogoutUrl;
          }
        });
        return;
        }
        
        else {
          this.globals.errorSweetAlert();
        }
      }
    );
  }
}
