import { Component, input, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { SubscriptionService } from '../services/subscription.service';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
declare var Swal: any;


@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrl: './subscription-list.component.css'
})
export class SubscriptionListComponent {
 

  subscriptionList = {
    data: [],
    total: 0,
  };

  
  duplicate_name: boolean = false;
  dialogOpened = false;
  commentdDialogOpened = false;
  form: UntypedFormGroup;
  pageSize: number = 10;
  allowUnsort = true;
  skip: number = 0;
 
  paginationEntity = {
    institute_id: this.globals.selectedInstitute?.id,
    
    limit: this.pageSize,
    offset: 0,
    search_text:'',
    
    sortOrder: [{
      
       field: 'created_at',
        dir: 'desc',
    }]
  };
  state = {
    skip: 0,
    take: this.pageSize
  };
  sort: SortDescriptor[] = [{
    field: 'created_at',
      dir: 'desc',
  }];
  gridLoading:boolean = false;
  discountType: any;
  availableLicense: any;

  constructor(
    public globals: Globals, 
    private router: Router, 
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  institute_id:any

  ngOnInit(): void {
    
    this.globals.breadcrumbs = ['Manage','Types',''];

    this.institute_id =
        this.globals.selected_institute != null
          ? this.globals.selected_institute.id
          : null;
      if (this.institute_id == null) {
        this.globals.isLoading = false;
        this.globals.dialogOpened = true;
       
      }else{
        this.gridLoading = true;
        this.getData();

      }

   
  }

  close() {
    this.dialogOpened = false;
    this.commentdDialogOpened = false;
  }


  getData() {
   
    this.gridLoading = true;
    this.subscriptionService.getAll(this.paginationEntity)
      .then((data: any) => {
        this.subscriptionList = {
          data: data.count > 0 ? orderBy(data.data, this.sort) : [],
          total: data.count,
        }


        this.gridLoading = false;
      },
      (error) => {
        this.gridLoading = false;
        this.skip = this.skip > this.subscriptionList.total ? 0 : this.skip;
        this.globals.errorSweetAlert();
      });
  }

  
  // pageChange Event
  public pageChange(event: PageChangeEvent): void {
    this.skip = (this.pageSize == event.take) ? event.skip : ((this.skip < event.take) ? event.skip : this.skip);
    this.pageSize = event.take;
    this.paginationEntity.offset = Math.floor((this.skip / this.pageSize));
    this.paginationEntity.limit = event.take;
    this.getData();
    this.globals.scrollToTop();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != "undefined") {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getData();
    }
  }

  // Filter event

  gridSearchChange: (...args: any[]) => void = this.globals.debounce(this.onFilter.bind(this), 500);

  
  public onFilter(inputValue: string): void {
    if (inputValue != "" && inputValue.length>=1) {
      this.paginationEntity.offset = 0;
      this.paginationEntity.search_text = inputValue;
    
      this.getData();
    }  else if(inputValue == "") {
     
      this.paginationEntity.search_text='';
      this.pageChange(this.state);
    }
  }

  getById(id:any,action:any){
    if(action =='comment'){
      this.commentdDialogOpened = true;

    }
    else if(action =='unassign'){
      this.dialogOpened = true;

    }
    this.id = id;
      this.globals.isLoading = true;
      this.subscriptionService.getById(id)
      .then((data) => {
        this.globals.isLoading = false;
        var fields = data['data'];
      
        this.subscriptionNumber =  fields['total_licenses'];
        this.comment =  fields['comment'];
        this.used_licenses = fields.used_licenses;
        this.total_licenses= fields.total_licenses;
        this.assigned_licenses= fields.assigned_licenses;
        
        let avlblLicence = (this.total_licenses - this.used_licenses) - this.assigned_licenses
        this.availableLicense = avlblLicence;
        if(this.subscriptionNumber > avlblLicence){
          this.unassignButtonDisabled = true;
        }
       
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      });
  }

  changeStatus(id : any,action){


    Swal.fire({
      icon: 'warning',
      title: action+' Subscription',
      text: 'Are you sure, you want to ' +action.toLowerCase()+ ' this subscription?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.gridLoading = true;
        this.subscriptionService.changeSubscriptionStatus(id)
        .then((data) => {
          this.globals.isLoading = false;
          this.gridLoading = false;
          this.getData();
          this.globals.sweetAlert(
            'success',
            'Subscription '+action+'d',
            'Subscription '+action+'d successfully.'
          );

        },
        (error) => {
          this.globals.isLoading = false;
          this.gridLoading = false;

          this.globals.errorSweetAlert();
        });
      }
    });
 
  }

  id : any
  
  update(entity) {
    let entity1: any;
    
    const avlblLic = this.total_licenses;
    const finalTotal = avlblLic - this.subscriptionNumber
    if(this.commentdDialogOpened){
      entity1 = {
        comment: this.comment
      }
    }
    else{
      entity1 = {
        subscription_number : finalTotal,
      }
    }
    this.globals.isLoading = true;
    this.subscriptionService.update(entity1, this.id)
      .then((data) => {
        this.globals.isLoading = false;
        this.globals.sweetAlert('success','Subscription Updated','Subscription has been updated successfully.');
        this.router.navigate(['/manage/subscription/list']);
        this.dialogOpened = false;
        this.commentdDialogOpened = false;
        this.getData();
      },
      (error) => {
        this.globals.isLoading = false;
     
          this.globals.errorSweetAlert();
        
      });
  }

  subsValidationErr : any;
  negativeValErr:any;
  used_licenses:any; total_licenses:any;
  assigned_licenses:any;
  subscriptionNumber: any;
  comment: any = '';
  unassignButtonDisabled: any;

  getFormErrors(formGroup: FormGroup): any {
    const errors = {};
    
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
  
      if (control instanceof FormGroup) {
        const groupErrors = this.getFormErrors(control);
        if (Object.keys(groupErrors).length > 0) {
          errors[key] = groupErrors;
        }
      } else if (control instanceof FormArray) {
        const arrayErrors = [];
        control.controls.forEach((group: FormGroup, index: number) => {
          const groupErrors = this.getFormErrors(group);
          if (Object.keys(groupErrors).length > 0) {
            arrayErrors[index] = groupErrors;
          }
        });
        if (arrayErrors.length > 0) {
          errors[key] = arrayErrors;
        }
      } else {
        if (control.errors) {
          errors[key] = control.errors;
        }
      }
    });
  
    return errors;
  }
  

  subscriptionValidationErr(){

   let currentValue = this.subscriptionNumber
   let availableLicense = this.total_licenses - this.used_licenses - this.assigned_licenses;
   if(currentValue> availableLicense){
    this.subsValidationErr=true
    this.unassignButtonDisabled = true;
   }
   else{
    this.subsValidationErr =false
    this.unassignButtonDisabled = false;

   }

   if(this.subscriptionNumber < 0){
    this.negativeValErr = true;
   }else{
    this.negativeValErr = false;

   }
   
  }

}

