<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">System wise Details</h1>
                    <button kendoButton routerLink="/overview-dashboard" class="all_btn theme_btn float-end">Back to Overview</button>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            
                <div class="search_main_block">
                    <div class="clearfix"></div>
                    <div class="loader_table">
                        <div class="box_loader" *ngIf="gridLoading">
                            <div class="height_loader">
                                <div class="img_loader">
                                    <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                                    <div class="loader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <kendo-grid [data]="systemList" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
                            (pageChange)="pageChange($event)" [sort]="sort" [sortable]="{
                          allowUnsort: false,
                          mode: 'single'
                          }" (sortChange)="sortChange($event)" scrollable="none">
                            <kendo-grid-messages pagerItems="Systems" pagerItemsPerPage="Systems per page"
                                noRecords="No Systems available.">
                            </kendo-grid-messages>
                            <ng-template kendoGridToolbarTemplate>
                                <input placeholder="Search by system" kendoTextBox
                                    (input)="onFilter($event.target.value)" />
                                <kendo-pager-page-sizes [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                                <div class="clearfix"></div>
                            </ng-template>
                            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                <kendo-pager-numeric-buttons [buttonCount]="globals.pageIndex">
                                </kendo-pager-numeric-buttons>
                                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                                <kendo-pager-info></kendo-pager-info>
                            </ng-template>

                            <kendo-grid-column field="system_name" title="System" [headerStyle]="{width: '60%'}"  [style]="{width: '60%'}" media="(min-width: 769px)">
                            </kendo-grid-column>
                            <kendo-grid-column field="institute_count" title="Total Organizations" [headerClass]="'justify-content-center'" [headerStyle]="{width: '10%','text-align': 'center'}"  [style]="{width: '10%'}" media="(min-width: 769px)" class="text-center">
                            </kendo-grid-column>
                            <kendo-grid-column field="user_count" title="Total Users" [headerClass]="'justify-content-center'" [headerStyle]="{width: '10%','text-align': 'center'}"  [style]="{width: '10%'}" media="(min-width: 769px)"  class="text-center">
                            </kendo-grid-column>
                            <kendo-grid-column field="department_count" title="Total Divisions" [headerClass]="'justify-content-center'" [headerStyle]="{width: '10%','text-align': 'center'}"  [style]="{width: '10%'}" media="(min-width: 769px)"  class="text-center">
                            </kendo-grid-column>
                            <kendo-grid-column field="unit_count" title="Total Units" [headerClass]="'justify-content-center'" [headerStyle]="{width: '10%','text-align': 'center'}"  [style]="{width: '10%'}" media="(min-width: 769px)"  class="text-center">
                            </kendo-grid-column>
                           
                           
                            <kendo-grid-column field="system_name" title="System" width="300" media="(max-width: 768px)">
                            </kendo-grid-column>
                            <kendo-grid-column field="institute_count" title="Total Organizations" width="150" media="(max-width: 768px)" [headerClass]="'justify-content-center'"
                                [headerStyle]="{'text-align': 'center'}" class="text-center">
                            </kendo-grid-column>
                            <kendo-grid-column field="user_count" title="Total Users" width="150" media="(max-width: 768px)" [headerClass]="'justify-content-center'"
                                [headerStyle]="{'text-align': 'center'}" class="text-center">
                            </kendo-grid-column>
                            <kendo-grid-column field="department_count" title="Total Divisions" width="150" media="(max-width: 768px)" [headerClass]="'justify-content-center'"
                                [headerStyle]="{'text-align': 'center'}" class="text-center">
                            </kendo-grid-column>
                            <kendo-grid-column field="unit_count" title="Total Units" width="150" media="(max-width: 768px)" [headerClass]="'justify-content-center'"
                                [headerStyle]="{'text-align': 'center'}" class="text-center">
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>