import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
declare var $,Swal: any;

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
})
export class LandingDashboardComponent implements OnInit {

  instituteList;
  individual=null;
  role = 0;
  individual_present = false;
  amsn_present = false;
  oess_present = false;
  institute_count = 0;
  selectForm: UntypedFormGroup;
  disableBtn:boolean = true;
  org_register:boolean = false;

  constructor(public globals: Globals,private AuthService: AuthService, private router: Router, 
    private fb: UntypedFormBuilder, private CookieService: CookieService) { }

  ngOnInit(): void {  
    this.globals.breadcrumbs = ['Switch Dashboard','',''];
    this.globals.isLoading = true;
    this.selectForm = this.fb.group({
      role: ['', [Validators.required]]
    });
    $('input:radio').click(function () {
      $("label").removeClass('checked');
      $(this).parent().addClass('checked');
    });

    this.AuthService.getAuthUser()
    .then((data) => {

     
      if (this.globals.authData.list.length > 1) {
        this.instituteList = this.globals.authData.list;
        let individual_present = false; let oess_present = false; let amsn_present = false;
        let institute_count = 0;
        this.instituteList.forEach(function (value) {
          if(value.institute=="" && value.role_value==5){
            individual_present = true;
          } else if(value.institute=="" && value.role_value==1){
            oess_present = true;
          } else if(value.institute=="" && value.role_value==2){
            amsn_present = true;
          } else {
            institute_count++;
          }
        });
        this.oess_present = oess_present;
        this.amsn_present = amsn_present;
        this.individual_present = individual_present;
       
        this.institute_count = institute_count;
        let role = (this.globals.institute_details && this.globals.institute_details.role_value) ? this.globals.institute_details.role_value : 0;
        this.role = (role==4 || role==6) ? 3 : role;
        this.globals.isLoading = false;
        
      } else if (this.globals.authData.list.length == 1) {
        
        if(this.globals.authData.list[0].institute!='' && this.globals.authData.list[0].institute.is_active==0){
          this.globals.isLoading = false;
          this.org_register = true;
        
        } else {
          this.globals.authData.list[0].index_number = 0;
         
          const roleHierarchy = [1, 2, 3, 6, 4, 5];
          let highestPriorityRole = null;
          let highestRoleData = null;

          this.globals.authData.list.forEach((value) => {
           
            if (
              highestPriorityRole === null ||
              roleHierarchy.indexOf(value.role_value) < roleHierarchy.indexOf(highestPriorityRole)
            ) {
             
              highestPriorityRole = value.role_value;
              
              highestRoleData = value;
            }
          });
          
          
          if (highestRoleData) {
            this.CookieService.set(
              'institute_details',
              window.btoa(JSON.stringify(highestRoleData)),
              365,
              '/',
              this.globals.CookieDomainUrl
            );
          }
          
          if(this.globals.institute_details.role_value == 1 || this.globals.institute_details.role_value == 2){
            window.location.href = this.globals.baseUrl+'/overview-dashboard';
          } else {
            this.globals.selected_institute = this.globals.institute_details.institute;
            this.CookieService.set('selected_institute', window.btoa(JSON.stringify(this.globals.institute_details.institute)), 365, '/', this.globals.CookieDomainUrl);
            this.globals.saveRecentlyInstitutes(this.globals.institute_details.institute.id);
            window.location.href = this.globals.baseUrl+'/dashboard';
          }       
        }        
      } else if(this.globals.authData.list.length == 0) {
        this.globals.isLoading = false;
        this.org_register = true;
      
      }

    }, (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });

  }

  ngAfterViewInit (){    
    let check1 = (this.globals.institute_details && this.globals.institute_details.institute=="") ? true : false;
    let check2 = (this.globals.institute_details && this.globals.institute_details.institute!="") ? true : false;
    let index = check2 ? this.globals.institute_details.index_number : 0;
    let role = (this.globals.institute_details && this.globals.institute_details.role_value) ? this.globals.institute_details.role_value : "null";
    let self = this;
    setTimeout(function(){
    
      if(check1 && role==5){
        $('#Individual').prop("checked", true);
        self.disableBtn = false;
      } else if(check1 && role==1) {
        $('#oess').prop("checked", true);
        self.disableBtn = false;
      } else if(check1 && role==2) {
        $('#amsn').prop("checked", true);
        self.disableBtn = false;
      } else if(check2) {
        $('#Institute').prop("checked", true);
      }
      if(check2){
        $('#institute'+index).prop("checked", true);
        $(".institute_block").removeClass('checked');
        $("#institute"+index).parent().addClass('checked');
        self.disableBtn = false;
      }
      if(self.instituteList.length==self.institute_count){
        self.role = 3;
        $('#Institute').prop("checked", true);
      }
    }, 1000);
  }

  selectRole(role){ 
    if(role!=3){
      this.disableBtn = false;
    } else {
      this.disableBtn = true;
    }    
    this.role = role;
  }

  advanced_search_block(){
    $(".advanced_search_block").toggleClass("active");
    $(".search_main_block").toggleClass("right_slide");
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  changeRole(){ 
    if(this.role==0){
      this.globals.sweetAlert('warning','Select Role','Please select any role.');
    } else {
      localStorage.removeItem('user_role_permission');
      this.globals.permissions = {};
      if(this.role==5 || this.role==1 || this.role==2){
        let institute_details = null;
        if(this.role==5){          
          this.globals.authData.list.forEach(function (value) {
            if(value.institute=="" && value.role_value==5){
              institute_details = value;
            }
          });
        } else { 
          let role = this.role;       
          this.globals.authData.list.forEach(function (value) {
            if(value.role_value==role){
              institute_details = value;
            }
          });
        }
        this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details)), 365, '/', this.globals.CookieDomainUrl);
        this.globals.institute_details = institute_details;
        if(this.globals.institute_details.role_value == 1 || this.globals.institute_details.role_value == 2){
          window.location.href = this.globals.baseUrl+'/overview-dashboard';
        } else {
          this.globals.selected_institute = this.globals.institute_details.institute;
          this.CookieService.set('selected_institute', window.btoa(JSON.stringify(this.globals.institute_details.institute)), 365, '/', this.globals.CookieDomainUrl);
          this.globals.saveRecentlyInstitutes(this.globals.institute_details.institute.id);
          window.location.href = this.globals.baseUrl+'/dashboard';
        }            
      } else {
        if(this.institute_count==1){
          let institute_details = null;
          this.globals.authData.list.forEach(function (value) {
            if(value.institute!=""){
              institute_details = value;
            }
          });
          let index = $('input[name="role_select"]:checked').val();
          institute_details.index_number = index;
          this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details)), 365, '/', this.globals.CookieDomainUrl);
          this.globals.institute_details = institute_details;
          if(this.globals.institute_details.role_value == 1 || this.globals.institute_details.role_value == 2){
            window.location.href = this.globals.baseUrl+'/overview-dashboard';
          } else {
            this.globals.selected_institute = this.globals.institute_details.institute;
            this.CookieService.set('selected_institute', window.btoa(JSON.stringify(this.globals.institute_details.institute)), 365, '/', this.globals.CookieDomainUrl);
            this.globals.saveRecentlyInstitutes(this.globals.institute_details.institute.id);
            window.location.href = this.globals.baseUrl+'/dashboard';
          }         
        } else {
          let index = $('input[name="role_select"]:checked').val();
          if(index==undefined){
            this.globals.sweetAlert('warning','Select Organization','Please select any organization.');
          } else {
            this.globals.authData.list[index].index_number = index;
            this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[index])), 365, '/', this.globals.CookieDomainUrl);
            this.globals.institute_details = this.globals.authData.list[index];
            if(this.globals.institute_details.role_value == 1 || this.globals.institute_details.role_value == 2){
              window.location.href = this.globals.baseUrl+'/overview-dashboard';
            } else {
              this.globals.selected_institute = this.globals.institute_details.institute;
              this.CookieService.set('selected_institute', window.btoa(JSON.stringify(this.globals.institute_details.institute)), 365, '/', this.globals.CookieDomainUrl);
              this.globals.saveRecentlyInstitutes(this.globals.institute_details.institute.id);
              window.location.href = this.globals.baseUrl+'/dashboard';
            }       
          }
        }
      }
    }
  }

  selectOrg(index){ 
    $(".institute_block").removeClass('checked');
    $("#institute"+index).parent().addClass('checked');
    this.disableBtn = false;
  }

  clearRole(){
    this.globals.institute_details = null;
    if(this.individual_present){
      this.role = 0;

    }
    $('input[name="role"]:checked').prop("checked", false);
    $('input[name="role_select"]:checked').prop("checked", false);
    
    this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
    localStorage.removeItem('user_role_permission');
    this.globals.permissions = {};
  }

}
