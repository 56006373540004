import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from './../services/dashboard.service';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
declare var $, Swal: any;

@Component({
  selector: 'app-system-wise-details',
  templateUrl: './system-wise-details.component.html'
})
export class SystemWiseDetailsComponent implements OnInit {

  systemList = {
    'data': [],
    'total': 0
  };
  pageSize: number = 10;
  allowUnsort = true;
  skip: number = 0;
  paginationEntity = {
    limit: this.pageSize,
    offset: 1,
    searchData: {
      status: '',
      searchQuery: ''
    },
    sortOrder: [{
      field: "system_name",
      dir: 'asc'
    }]
  };
  state = {
    skip: 0,
    take: this.pageSize
  };
  sort: SortDescriptor[] = [{
    field: 'system_name',
    dir: 'asc'
  }];
  gridLoading: boolean = false;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private DashboardService: DashboardService) { }

  @ViewChild(DataBindingDirective, { static: false }) dataBinding: DataBindingDirective;

  ngOnInit(): void { 
    this.globals.breadcrumbs = ['Overview', 'System wise Details', ''];
   
    if (this.globals.institute_details.role_value == 2 || this.globals.institute_details.role_value == 1) {
      this.getSystemData();
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  getSystemData() {
    this.gridLoading = true;
    this.DashboardService.getOrganizationsBySystemForAdmin(this.paginationEntity)
      .then((data: any) => {
        this.systemList = {
          data: (data.count > 0) ? orderBy(data.data, this.sort) : [],
          total: data.count,
        }
        this.gridLoading = false;
        this.skip = this.skip > this.systemList.total ? 0 : this.skip;
      },
        (error) => {
          this.gridLoading = false;
          this.globals.errorSweetAlert();
        });
    
  }

  // pageChange Event
  public pageChange(event: PageChangeEvent): void {
    this.skip = (this.pageSize == event.take) ? event.skip : ((this.skip < event.take) ? event.skip : this.skip);
    this.pageSize = event.take;
    this.paginationEntity.offset = Math.floor((this.skip / this.pageSize) + 1);
    this.paginationEntity.limit = event.take;
    this.getSystemData();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != "undefined") {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getSystemData();
    }
  }

  // Filter event
  public onFilter(inputValue: string): void {
   
    this.gridLoading = true;
    if (inputValue != "") {
      this.paginationEntity.offset = 1;
      this.paginationEntity.searchData.searchQuery = inputValue;
      this.getSystemData();
    } else {
      this.paginationEntity.searchData.searchQuery = '';
      this.pageChange(this.state);
    }
  }


}
