<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">

                    <h1 class="float-start">Subscription Pack List</h1>
                    <button kendoButton routerLink="/manage/subscription/add" class="all_btn theme_btn float-end"
                        *ngIf="globals.permissions['add-subscription'] && globals.institute_details.role_value !== 3">Add a Subscription Pack</button>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <div *ngIf="globals.institute_details.role_value !== 3" class="filter_block mb-3">
                    <ul>
                        <li class="blue"><i class="fa fa-comment"></i>Comments</li>
                        <li class="grey"><i class="fa fa-undo"></i>Unassign</li>
                        <li class="red"><i class="fa fa-times"></i>Deactivate</li>
                        <li class="green"><i class="fa fa-check"></i>Activate</li>
                    </ul>
                </div>


                <div class="clearfix"></div>
                <div class="loader_table">
                    <div class="box_loader" *ngIf="gridLoading">
                        <div class="height_loader">
                            <div class="img_loader">
                                <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                                <div class="loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <kendo-grid [data]="subscriptionList" [pageSize]="pageSize" [skip]="skip"
                        [pageable]="true" (pageChange)="pageChange($event)"
                        [sort]="sort" [sortable]="{
                          allowUnsort: false,
                          mode: 'single'
                          }" (sortChange)="sortChange($event)" scrollable="none">
                        <kendo-grid-messages *ngIf="subscriptionList.total == 0" pagerItems="Subscription Packs"
                            pagerItemsPerPage="Subscription packs per page"
                            noRecords="No subscription packs available.">
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate>
                            <input placeholder="Search by order ID and total subscriptions" kendoTextBox
                                (input)="gridSearchChange($event.target.value)" />
                            <kendo-pager-page-sizes
                                [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                        </ng-template>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons *ngIf="subscriptionList?.total > 0"></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons 
                                [buttonCount]="globals.pageIndex"></kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons *ngIf="subscriptionList?.total > 0"></kendo-pager-next-buttons>
                            <kendo-pager-info></kendo-pager-info>
                        </ng-template>
                       
                        <kendo-grid-column field="subscription_order_id" title="Order Id" [headerStyle]="{width: '15%'}"
                            [style]="{width: '15%'}" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.subscription_order_id != null ? dataItem.subscription_order_id : 'NA'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="total_licenses" title="Total Subscriptions" [headerClass]="'justify-content-center'"
                            [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" class="text-center">
                        </kendo-grid-column>

                        <kendo-grid-column field="unused_subscriptions" title="Unused Subscriptions" [headerClass]="'justify-content-center'"
                            [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" class="text-center">
                        </kendo-grid-column>



                        <kendo-grid-column field="total_license_of_users" title="Assigned to Users" [headerClass]="'justify-content-center'"
                            [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" class="text-center">
                          
                        </kendo-grid-column>

                        
                        <kendo-grid-column field="assigned_licenses" title="Assigned To Divisions" [headerClass]="'justify-content-center'"
                            [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" class="text-center">



                           
                        </kendo-grid-column>

                    

                        <kendo-grid-column field="license_expire" title="Expiry Date" [headerStyle]="{width: '12%'}"
                            [style]="{width: '12%'}" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.license_expire | date:"MMMM d, yyyy" }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?" [headerClass]="'justify-content-center'"
                            [headerStyle]="{width: '7%','text-align': 'center'}" [style]="{width: '7%'}"
                            media="(min-width: 769px)" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subscription']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Actions" [headerStyle]="{width: '10%','text-align': 'center'}" [headerClass]="'justify-content-center'"
                            [style]="{width: '10%'}" media="(min-width: 769px)" [sortable]="false" class="text-center"
                            *ngIf="globals.institute_details.role_value !== 3 && (globals.permissions['edit-subscription'] || globals.permissions['delete-subscription'])">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    
                                </div>
                              
                                <div kendoTooltip>
                                    <button kendoButton (click)="getById(dataItem.id,'comment')" title="Comments"
                                        class="icon_btn blue" *ngIf="globals.permissions['edit-subscription']"><i
                                            class="fa fa-comment"></i></button>

                                    <button [disabled]="dataItem.total_licenses == null" kendoButton
                                        (click)="getById(dataItem.id,'unassign')" title="Unassign" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-subscription']"><i
                                            class="fa fa-undo"></i></button>

                                                <button *ngIf="dataItem.is_active == 1" title="Deactivate"
                                                    (click)="changeStatus(dataItem.id,'Deactivate')"
                                                    class="icon_btn red">
                                                    <i class="fa fa-times"></i>
                                                </button>                                           
                                            

                                    <button *ngIf="dataItem.is_active == 0" title="Activate"
                                        (click)="changeStatus(dataItem.id,'Activate')" class="icon_btn green"><i
                                            class="fa fa-check"></i></button>
                                  
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                      
                        <kendo-grid-column field="subscription_order_id" title="Order Id" width="200"
                            media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.subscription_order_id != null ? dataItem.subscription_order_id : 'NA'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="total_licenses" title="Total Subscriptions" width="200"
                            media="(max-width: 768px)">
                        </kendo-grid-column>

                        <kendo-grid-column field="unused_subscriptions" title="Unused Subscriptions" width="200"
                            media="(max-width: 768px)">
                        </kendo-grid-column>

                       
                        <kendo-grid-column field="total_license_of_users" title="Assigned to Users" width="200"
                            media="(max-width: 768px)">
                           
                        </kendo-grid-column>

                        <kendo-grid-column field="assigned_licenses" title="Assigned To Divisions" width="200"
                            media="(max-width: 768px)">
                            
                        </kendo-grid-column>



                    


                        <kendo-grid-column field="license_expire" title="Expiry Date" width="100"
                            media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              
                                {{ dataItem.license_expire | date:"MMMM d, yyyy hh:mm a" }}
                            </ng-template>

                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?" width="95" media="(max-width: 768px)" [headerClass]="'justify-content-center'"
                            [headerStyle]="{'text-align': 'center'}" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subscription']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Actions" width="85" media="(max-width: 768px)" [headerClass]="'justify-content-center'"
                            [headerStyle]="{'text-align': 'center'}" [sortable]="false" class="text-center"
                            *ngIf="globals.institute_details.role_value !== 3 && (globals.permissions['edit-subscription'] || globals.permissions['delete-subscription'])">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                   
                                </div>
                                <div kendoTooltip>
                                    <button kendoButton (click)="getById(dataItem.id, 'comment')" title="Comment"
                                        class="icon_btn blue"><i class="fa fa-comment"></i></button>
                                    <button [disabled]="dataItem.total_licenses == null" kendoButton
                                        (click)="getById(dataItem.id, 'unassign')" title="Unassign"
                                        class="icon_btn grey"><i class="fa fa-undo"></i></button>

                                            <button *ngIf="dataItem.is_active == 1"
                                                (click)="changeStatus(dataItem.id,'Deactivate')"
                                                 class="icon_btn red">
                                                <i class="fa fa-times"></i>
                                            </button>
                                                                             

                                    <button *ngIf="dataItem.is_active == 0" title="Activate"
                                        (click)="changeStatus(dataItem.id,'Activate')" class="icon_btn green"><i
                                            class="fa fa-check"></i></button>
                                    
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<kendo-dialog *ngIf="dialogOpened" [minWidth]="550" [width]="550" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 class="modal-title" id="switch_org_modalLabel">Unassign Subscriptions</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

    

        <form class="k-form" #form="ngForm">

            <kendo-formfield>
                <label><span>*</span>No. of subscription you can unassign ({{availableLicense}})</label>
                <kendo-numerictextbox format="##" [min]="1" name="subscription_number" [(ngModel)]="subscriptionNumber"
                    #subscription="ngModel" (valueChange)="subscriptionValidationErr()">
                </kendo-numerictextbox>

                <kendo-formerror *ngIf="subsValidationErr">
                    Please enter no. of subscription.
                </kendo-formerror>



                <div class="k-form-error" *ngIf="subsValidationErr">
                    Only unassigned subscriptions can be removed. Current unassigned subscriptions: {{total_licenses -
                    used_licenses - assigned_licenses}}.
                </div>

                <div class="k-form-error" *ngIf="negativeValErr">
                    Please enter a valid number.
                </div>
            </kendo-formfield>

        </form>


        <div class="footer_button text-end">
            <button (click)="dialogOpened = false;" type="button" class="all_btn themeoption_btn">Cancel</button>
            <button
                [disabled]="negativeValErr || unassignButtonDisabled || subscriptionNumber == 0 || subscriptionNumber == null"
                (click)="update(this.form.value)" type="button" class="all_btn theme_btn">Unassign</button>
        </div>
    </div>

</kendo-dialog>


<kendo-dialog *ngIf="commentdDialogOpened" [minWidth]="550" [width]="550" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 class="modal-title" id="switch_org_modalLabel">Comments</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

    



        <form class="k-form" #form="ngForm">

            <kendo-formfield>
            

                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                    <kendo-formfield>
                        <label> Comment</label>
                        <kendo-editor class="editor_style" name="comment" [(ngModel)]="comment" #comments="ngModel">
                            <kendo-toolbar>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                    </kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                    </kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                    </kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                    </kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                    </kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                             
                            </kendo-toolbar>
                        </kendo-editor>
                    </kendo-formfield>
                </div>

                
            </kendo-formfield>

        </form>


        <div class="footer_button text-end">
            <button (click)="commentdDialogOpened = false;" type="button" class="all_btn themeoption_btn">Cancel</button>
            <button (click)="update(this.form.value)" type="button" class="all_btn theme_btn">Submit</button>
        </div>
    </div>

</kendo-dialog>