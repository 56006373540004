<a href="https://faqs.ifma.uatbyopeneyes.com/home" class="faqs-fix" target="_blank">FAQ<small>s</small></a>
<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7">
                <div class="copyright_terms_block">
                    Copyright &copy; <span class="footer_year"></span>, <a href="https://www.ifma.org/" target="_blank">International Facility Management Association (IFMA)</a>. |
                    Powered by <a href="https://www.theopeneyes.com/" target="_blank">OpenEyes Technologies Inc.</a>
                </div>
                <div class="copyright_terms_block mt-2">No materials, including graphics, may be reused, modified, or
                    reproduced without written permission. | Version: {{CurrentVersion}}</div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
                <div class="social_links_block">
					
                    <!-- <a href="https://faqs.ifma.uatbyopeneyes.com/home" class="feedback_a" target="_blank">FAQs</a> -->
                  
					<!-- <a href="#" class="feedback_a" target="_blank">Admin Manual</a> -->
                    <!-- <a href="https://faqs.ifma.uatbyopeneyes.com/home" class="feedback_a" target="_blank">FAQs</a> -->
                    <!-- <a href="#" class="feedback_a" target="_blank">Share a Feedback</a> -->
                    <a href="https://www.facebook.com/InternationalFacilityManagementAssociation" target="_blank"><i class="fa fa-facebook"></i></a>
                    <a href="https://twitter.com/IFMA" target="_blank">
                        <!-- <i class="fa fa-twitter"></i> -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                    </a>
                    <a href="https://www.linkedin.com/company/ifma" target="_blank"><i
                            class="fa fa-linkedin-square"></i></a>
                    <a href="https://www.instagram.com/ifma_hq/" target="_blank"><i class="fa fa-instagram"></i></a>
                    <a href="https://www.youtube.com/ifmaglobal" target="_blank"><i
                            class="fa fa-youtube-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="clearfix"></div>