import { DashboardService } from './../services/dashboard.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CookieService } from 'ngx-cookie-service';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  statusList: any = ['All', 'Organizations'];
  institute_id = this.globals.institute_details.institute.id;
  divisionCount = 0;
  unitCount = 0;
  userCount = 0;
  cohortCount =0;
  connectedOn = 'N/A';
  recentlyConnectedUsers = [];
  loader = {
    userChart: false,
    recentUser: false,
    divisionChart: false,
    unitChart: false,
    divisionAndUnitChart: false,
  };
  dataNotAvailable = {
    userChart: false,
    divisionChart: false,
    unitChart: false,
    divisionAndUnitChart: false,
  };

  constructor(
    public globals: Globals,
    private router: Router,
    private CookieService: CookieService,
    private DashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Dashboard', '', ''];
    this.globals.isLoading = true;

    setTimeout(()=>{
      this.globals.isLoading = false;
    },3000)


    this.institute_id =
      this.globals.selected_institute != null
        ? this.globals.selected_institute.id
        : null;
    if (this.institute_id == null) {
      this.globals.isLoading = false;
      this.globals.dialogOpened = true;
      this.dataNotAvailable = {
        userChart: true,
        divisionChart: true,
        unitChart: true,
        divisionAndUnitChart: true,
      };
    } else {
      this.getData();
    }



  }

  getData() {
    this.loader.userChart = true;
    this.loader.recentUser = true;
    this.loader.divisionChart = true;
    this.loader.unitChart = true;
    this.loader.divisionAndUnitChart = true;
    this.refreshLicensePack();
    this.getCounts();
    this.getUsersByDivision();
    this.getRecentlyConnectedUsers();
    this.getTopDivisionsAndUnits();
  }

  getCounts() {
    this.DashboardService.getCounts({ institute_id: this.institute_id }).then(
      (data) => {
        this.divisionCount = data['data'].division_count;
        this.unitCount = data['data'].unit_count;
        this.userCount = data['data'].user_count;
        this.cohortCount = data['data'].cohort_count;
        var connectedOn = new Date(data['data'].create_date),
          year = connectedOn.getFullYear(),
          month = connectedOn.toLocaleString('default', { month: 'long' });
        this.connectedOn = month + ' ' + year;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getUsersByDivision() {
    this.DashboardService.getUserData({
      institute_id: this.institute_id,
      type: 'Users',
    }).then(
      (data) => {
        var chartData = [];
        for (const [key, value] of Object.entries(data['data'].users_details)) {
          chartData.push({
            Category: value['department_name'],
            users: value['users'],
          });
        }

        if (chartData.length == 0) {
          this.dataNotAvailable.userChart = true;
        } else {
          this.dataNotAvailable.userChart = false;
          setTimeout(function () {
            am4core.ready(function () {
              var chart = am4core.create('user_chart', am4charts.PieChart);
              am4core.addLicense('CH236795548');
           
              chart.responsive.enabled = true;

              chart.data = chartData;

              var pieSeries = chart.series.push(new am4charts.PieSeries());
              pieSeries.dataFields.value = 'users';
              pieSeries.dataFields.category = 'Category';

              chart.innerRadius = am4core.percent(30);
              var label = chart.seriesContainer.createChild(am4core.Label);
              label.html =
                'Total Users<br><span style="text-align:center; font-weight:bold;display:block;">' +
                data['data'].total_users +
                '</span>';
              label.horizontalCenter = 'middle';
              label.verticalCenter = 'middle';
              label.fontSize = 13;

              pieSeries.slices.template.stroke = am4core.color('#fff');
              pieSeries.slices.template.strokeWidth = 2;
              pieSeries.slices.template.strokeOpacity = 1;
             
              pieSeries.ticks.template.disabled = false;
              pieSeries.labels.template.text = '{Category}:{users}';
              pieSeries.labels.template.fontSize = 12;
              pieSeries.labels.template.fill = am4core.color('black');
              pieSeries.labels.template.maxWidth = 70;
              pieSeries.labels.template.wrap = true;

              pieSeries.slices.template.propertyFields.fill = "color";
              pieSeries.colors.list = [
                am4core.color("#0c3353"),
                am4core.color("#508fb0"),
                am4core.color("#46757f"),
                am4core.color("#6b7a89"),
                am4core.color("#675c5a")
              ];

              let slice = pieSeries.slices.template;
              slice.states.getKey('hover').properties.scale = 1;
              slice.states.getKey('active').properties.shiftRadius = 0;

              chart.legend = new am4charts.Legend();
              chart.legend.position = 'bottom';
              chart.legend.paddingTop = 15;
              chart.legend.paddingBottom = 0;
              chart.legend.fontSize = 12;
              chart.legend.labels.template.width = 300;
              let markerTemplate = chart.legend.markers.template;
              markerTemplate.width = 12;
              markerTemplate.height = 12;
              chart.legend.valueLabels.template.disabled = true;
              pieSeries.colors.step = 2;
            }); 
          }, 500);
        }

        this.loader.userChart = false;
      },
      (error) => {
        this.dataNotAvailable.userChart = true;
        this.loader.userChart = false;
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getRecentlyConnectedUsers() {
    this.DashboardService.getUserData({
      institute_id: this.institute_id,
      type: 'RecentlyAddUsers',
    }).then(
      (data) => {
        this.recentlyConnectedUsers = data['data'];
        this.loader.recentUser = false;
      },
      (error) => {
        this.loader.recentUser = false;
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getTopDivisionsAndUnits() {
    this.DashboardService.getTopDivisionsAndUnits({
      institute_id: this.institute_id,
    }).then(
      (data) => {
        var topDivisions = data['data'].TopDivisions;
        var topUnits = data['data'].TopUnits;

        var divisionChartData = [];
        for (const [key, value] of Object.entries(topDivisions)) {
          divisionChartData.push({
            name: value['department_name'],
            value: value['users'],
          });
        }

        var unitChartData = [];
        for (const [key, value] of Object.entries(topUnits)) {
          unitChartData.push({
            name: value['department_name'] + ': ' + value['unit_name'],
            value: value['users'],
          });
        }

        var divisionAndUnitChartData = [];

        if (unitChartData.length > divisionChartData.length) {
          for (var i = 0; i < unitChartData.length; i++) {
            var row = {};
            row['unit'] = unitChartData[i].name;
            row['unit_count'] = unitChartData[i].value;
            if (divisionChartData[i]) {
              row['division'] = divisionChartData[i].name;
              row['division_count'] = divisionChartData[i].value;
            }
            divisionAndUnitChartData.push(row);
          }
        } else {
          for (var i = 0; i < divisionChartData.length; i++) {
            var row = {};
            row['division'] = divisionChartData[i].name;
            row['division_count'] = divisionChartData[i].value;
            if (unitChartData[i]) {
              row['unit'] = unitChartData[i].name;
              row['unit_count'] = unitChartData[i].value;
            }
            divisionAndUnitChartData.push(row);
          }
        }

        if (divisionChartData.length == 0) {
          this.dataNotAvailable.divisionChart = true;
        } else {
          this.dataNotAvailable.divisionChart = false;
       
        }

        if (unitChartData.length == 0) {
          this.dataNotAvailable.unitChart = true;
        } else {
          this.dataNotAvailable.unitChart = false;
        
        }

        if (divisionAndUnitChartData.length == 0) {
          this.dataNotAvailable.divisionAndUnitChart = true;
        } else {
          this.dataNotAvailable.divisionAndUnitChart = false;
          setTimeout(function () {
            am4core.ready(function () {
              am4core.addLicense('CH236795548');

              var mainContainer = am4core.create(
                'division_unit_chart',
                am4core.Container
              );
              mainContainer.width = am4core.percent(100);
              mainContainer.height = am4core.percent(100);
              mainContainer.layout = 'horizontal';

              var usData = divisionAndUnitChartData;

              var maleChart = mainContainer.createChild(am4charts.XYChart);
              maleChart.paddingRight = 0;
              maleChart.data = JSON.parse(JSON.stringify(usData));

              // Create axes
              var maleCategoryAxis = maleChart.yAxes.push(
                new am4charts.CategoryAxis()
              );
              maleCategoryAxis.dataFields.category = 'division';
              maleCategoryAxis.renderer.inversed = true;
              maleCategoryAxis.renderer.minGridDistance = 5;
              maleCategoryAxis.renderer.grid.template.disabled = true;
              maleCategoryAxis.renderer.labels.template.wrap = true;
              maleCategoryAxis.renderer.labels.template.width = 200;
              maleCategoryAxis.renderer.labels.template.textAlign = 'end';

              var maleValueAxis = maleChart.xAxes.push(
                new am4charts.ValueAxis()
              );
              maleValueAxis.renderer.inversed = true;
              maleValueAxis.min = 0;
              maleValueAxis.renderer.axis.opacity = 0;

              // Create series
              var maleSeries = maleChart.series.push(
                new am4charts.ColumnSeries()
              );
              maleSeries.dataFields.valueX = 'division_count';
             
              maleSeries.dataFields.categoryY = 'division';
              maleSeries.interpolationDuration = 1000;
              maleSeries.columns.template.fill = am4core.color('#209cb7');
              maleSeries.columns.template.stroke = am4core.color('#209cb7');
              maleSeries.sequencedInterpolation = true;
              maleSeries.columns.template.strokeOpacity = 0;
              maleSeries.columns.template.column.cornerRadiusTopLeft = 5;
              maleSeries.columns.template.column.cornerRadiusBottomLeft = 5;

              let valueLabel1 = maleSeries.bullets.push(
                new am4charts.LabelBullet()
              );
              valueLabel1.label.text = '{division_count} Users';
              valueLabel1.label.fontSize = 13;
              valueLabel1.label.dx = 35;
              valueLabel1.label.fill = am4core.color('#fff');

              var femaleChart = mainContainer.createChild(am4charts.XYChart);
              femaleChart.paddingLeft = 0;
              femaleChart.data = JSON.parse(JSON.stringify(usData));

              // Create axes
              var femaleCategoryAxis = femaleChart.yAxes.push(
                new am4charts.CategoryAxis()
              );
              femaleCategoryAxis.renderer.opposite = true;
              femaleCategoryAxis.dataFields.category = 'unit';
              femaleCategoryAxis.renderer.minGridDistance = 5;
              femaleCategoryAxis.renderer.inversed = true;
              femaleCategoryAxis.renderer.grid.template.disabled = true;
              femaleCategoryAxis.renderer.labels.template.wrap = true;
              femaleCategoryAxis.renderer.labels.template.width = 200;

              var femaleValueAxis = femaleChart.xAxes.push(
                new am4charts.ValueAxis()
              );
              femaleValueAxis.min = 0;
              femaleValueAxis.renderer.axis.opacity = 0;

              // Create series
              var femaleSeries = femaleChart.series.push(
                new am4charts.ColumnSeries()
              );
              femaleSeries.dataFields.valueX = 'unit_count';
             
              femaleSeries.fill = femaleChart.colors.getIndex(4);
              femaleSeries.stroke = femaleSeries.fill;
              femaleSeries.sequencedInterpolation = true;
              femaleSeries.columns.template.fill = am4core.color('#6e61aa');
              femaleSeries.columns.template.stroke = am4core.color('#6e61aa');
              femaleSeries.dataFields.categoryY = 'unit';
              femaleSeries.columns.template.strokeOpacity = 0;
              femaleSeries.columns.template.column.cornerRadiusTopRight = 5;
              femaleSeries.columns.template.column.cornerRadiusBottomRight = 5;

              let valueLabel = femaleSeries.bullets.push(
                new am4charts.LabelBullet()
              );
              valueLabel.label.text = '{unit_count} Users';
              valueLabel.label.fontSize = 13;
              valueLabel.label.dx = -35;
              valueLabel.label.fill = am4core.color('#fff');
            }); // end am4core.ready()
          }, 500);
        }

        this.loader.divisionChart = false;
        this.loader.unitChart = false;
        this.loader.divisionAndUnitChart = false;
      },
      (error) => {
        this.dataNotAvailable.divisionChart = true;
        this.dataNotAvailable.unitChart = true;
        this.dataNotAvailable.divisionAndUnitChart = true;

        this.loader.divisionChart = false;
        this.loader.unitChart = false;
        this.loader.divisionAndUnitChart = false;
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  goToEditOrganization() {
    if (
      this.globals.institute_details &&
      (this.globals.institute_details.role_value == 2 ||
        this.globals.institute_details.role_value == 1)
    ) {
      this.router.navigate(['/manage/organization/edit/' + this.institute_id]);
    } else {
      this.router.navigate(['/manage/organization/update']);
    }
  }

  refreshLicensePack() {
    this.DashboardService.refreshLicensePack({
      institute_id: this.institute_id,
    }).then(
      (data: any) => {},
      (error) => {
        this.globals.isLoading = false;
       
      }
    );
  }

  buyOrgLic() {
    this.CookieService.set(
      'org_lic_redirect',
      'true',
      1,
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href = 'https://www.amsn.org/oclp';
  }
}
