import { Component, input, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { CouponService } from '../services/coupon.service';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { filter } from 'rxjs/operators';
declare var $, Swal: any;

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrl: './coupon-list.component.css'
})
export class CouponListComponent {


  couponList = {
    data: [],
    total: 0,
  };

  pageSize: number = 10;
  allowUnsort = true;
  skip: number = 0;
  paginationEntity = {
    limit: this.pageSize,
    offset: 0,
    search_text: '',
    
    sortOrder: [{
      
      field: 'created_at',
      dir: 'desc',
    }]
  };
  state = {
    skip: 0,
    take: this.pageSize
  };
  sort: SortDescriptor[] = [{
    field: 'created_at',
    dir: 'desc',
  }];
  gridLoading: boolean = false;
  discountType: any;
  paginationData: any;

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private couponService: CouponService
  ) { }

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Manage', 'Types', ''];
    this.gridLoading = true;
    var data: any = JSON.parse(localStorage.getItem('pagination_flag'))
    if (data) {
      this.pageChange(data);
      localStorage.clear()
    }
    else {
      this.pageChange(this.state);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustWrapContentHeight();

      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe((event: NavigationEnd) => {
          this.adjustWrapContentHeight();
        });

    }, 1000)
  }


  adjustWrapContentHeight(): void {
    if ($(window).width() < 3000) {
      const headerHeight = $('header').outerHeight() || 0;
      const navHeight = $('.nagivation_block').outerHeight() || 0;
      const footerHeight = $('footer').outerHeight() || 0;
      const windowHeight = $(window).height() || 0;
      const wrapContentHeight = windowHeight - headerHeight - navHeight - footerHeight;
      $('.inner_content_block').css('min-height', `${wrapContentHeight}px`);
    } else {
      $('.inner_content_block').css('min-height', 'auto');
    }
  }




  getData() {

    this.gridLoading = true;
    let coupon_type_id = this.discountType;
    let entity = coupon_type_id
    this.couponService.getAll(this.paginationEntity, entity,)
      .then((data: any) => {
        this.couponList = {
          data: data.count > 0 ? orderBy(data.data, this.sort) : [],
          total: data.count,
        }

     
        this.gridLoading = false;
      },
        (error) => {
          this.gridLoading = false;
          this.skip = this.skip > this.couponList.total ? 0 : this.skip;
          this.globals.errorSweetAlert();
        });
  }

  

  delete(dataItem) {
    Swal.fire({
      icon: 'warning',
      title: 'Delete Coupon' + ' - ' + dataItem.name,
      text: 'Are you sure you want to delete this coupon?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.gridLoading = true;
          this.couponService.delete(dataItem.id)
            .then((data) => {
              this.gridLoading = false;
              this.getData();
              this.globals.sweetAlert('success', 'Coupon Deleted', 'Coupon has been deleted successfully.');
            },
              (error) => {
                this.gridLoading = false;
                this.globals.errorSweetAlert();
              });
        }
      })
  }

  storePagination() {
    this.paginationData = {
      skip: this.skip,
      take: this.pageSize,
      limit: this.paginationEntity.limit
    };
    localStorage.setItem('pagination_flag', JSON.stringify(this.paginationData));
  }


  edit(id) {
    this.storePagination();
    this.router.navigate(['/manage/coupon/edit/' + window.btoa(id)]);
  }

  // pageChange Event
  public pageChange(event: PageChangeEvent): void {
    this.skip = (this.pageSize == event.take) ? event.skip : ((this.skip < event.take) ? event.skip : this.skip);
    this.pageSize = event.take;
    this.paginationEntity.offset = Math.floor((this.skip / this.pageSize));
    this.paginationEntity.limit = event.take;
    this.getData();
    this.globals.scrollToTop();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != "undefined") {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getData();
    }
  }

  // Filter event

  gridSearchChange: (...args: any[]) => void = this.globals.debounce(this.onFilter.bind(this), 500);

  public onFilter(inputValue: string): void {
    if (inputValue != "" && inputValue.length > 0) {
      this.paginationEntity.offset = 0;
      this.paginationEntity.search_text = inputValue;
      this.getData();
    } else if (inputValue == "") {
      this.paginationEntity.search_text = '';
      this.pageChange(this.state);
    }
  }

}

