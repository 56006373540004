import { Component, NgZone, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { DashboardService } from './../services/dashboard.service';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CookieService } from 'ngx-cookie-service';
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_frozen from "@amcharts/amcharts4/themes/frozen";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import { load } from '@amcharts/amcharts4/.internal/core/utils/Net';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
})
export class AdminDashboardComponent implements OnInit {

  statusList: any = ['All', 'Organizations'];
  institute_id = this.globals.institute_details?.institute.id;
  systemCount = 0;
  organizationCount = 0;
  divisionCount = 0;
  unitCount = 0;
  userCount = 0;
  loader = {
    organizationsBySystems: false,
    organizationsByOwnership: false,
    organizationsByType: false,
    recentlyConnectedOrganizations: false,
    topOrganizations: false,
    stateWiseOrganizationsAndUsers: false
  };
  dataNotAvailable = {
    organizationsBySystems: false,
    organizationsByOwnership: false,
    organizationsByType: false,
    topOrganizations: false,
    stateWiseOrganizationsAndUsers: false
  };
  recentlyConnectedOrganizations: any;
  stateForm: UntypedFormGroup;

  system_sort = 'system_name';
  paginationEntity = {
    limit: 10,
    offset: 0,
    searchData: {
      status: '',
      searchQuery: ''
    },
    sortOrder: [{
      field: this.system_sort,
      dir: 'asc'
    }]
  };  
  listItems = [
    { 'text': 'System', value: 'system_name' },
    { 'text': 'Organization', value: 'institute_count' },    
    { 'text': 'Division', value: 'department_count' },
    { 'text': 'Unit', value: 'unit_count' },
    { 'text': 'User', value: 'user_count' }
  ];

  constructor(public globals: Globals, private formBuilder: UntypedFormBuilder, private cookieService: CookieService, private DashboardService: DashboardService) { }

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Overview', '', ''];
    this.globals.isLoading = true;
    setTimeout(()=>{
      this.globals.isLoading = false;
    },3000);
    this.stateForm = this.formBuilder.group({
      switch_state: [false]
    });
    this.getData();

  }

  getData() {

    this.loader = {
      organizationsBySystems: true,
      organizationsByOwnership: true,
      organizationsByType: true,
      recentlyConnectedOrganizations: true,
      topOrganizations: true,
      stateWiseOrganizationsAndUsers: true
    };
    this.getCounts();
    
    this.getRecentlyConnectedOrganizations();
    this.getStateWiseOrganization();
  }

  

  getCounts() {
    this.DashboardService.getCountForAdmin({ 'type': 'CountData' })
      .then((data) => {
        this.systemCount = data['data'].system_counts;
        this.organizationCount = data['data'].orginazation_counts;
        this.divisionCount = data['data'].division_count;
        this.unitCount = data['data'].unit_count;
        this.userCount = data['data'].user_count;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  changeSortBySystem(){
    this.loader.organizationsBySystems = true;
  }


  getRecentlyConnectedOrganizations() {
    this.DashboardService.getCountForAdmin({ 'type': 'RecentlyConnectedOrganizations' })
      .then((data) => {
        this.recentlyConnectedOrganizations = data['data'];
        this.loader.recentlyConnectedOrganizations = false;
      },
        (error) => {
          this.loader.recentlyConnectedOrganizations = false;
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }


  getStateWiseOrganization() {
    this.DashboardService.getStatewiseCountForAdmin({ 'type': 'byOrganizations' })
    .then((data) => {
      let org_data = data['data'];
      this.loader.stateWiseOrganizationsAndUsers = false;
      setTimeout(function () {

        am4core.ready(function () {
          // Create map instance
          var chart = am4core.create("map_chart_org", am4maps.MapChart);
          am4core.addLicense("CH236795548");
          chart.logo.hidden = true;
          chart.maxZoomLevel = 64;
          chart.responsive.enabled = true;
  
          // Set map definition
          chart.geodata = am4geodata_usaLow;
  
          // Set projection
          // chart.projection = new am4maps.projections.AlbersUsa();
  
          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
          polygonSeries.useGeodata = true;
          polygonSeries.calculateVisualCenter = true;
  
          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.tooltipText = "{name}";
          polygonTemplate.fill = am4core.color("#209cb7");
  
          // Load data when map polygons are ready
          chart.events.on("ready", loadStores);
  
          // Loads store data
          function loadStores() {
            var loader = new am4core.DataSource();
            loader.data = org_data;
            setupStores(loader.data);  
            loader.load();
          }
  
          // Creates a series
          function createSeries(heatfield) {
            var series = chart.series.push(new am4maps.MapImageSeries());
            series.dataFields.value = heatfield;
  
            var template = series.mapImages.template;
            template.verticalCenter = "middle";
            template.horizontalCenter = "middle";
            template.propertyFields.latitude = "lat";
            template.propertyFields.longitude = "long";
            template.tooltipText = "{name}:\n[bold]{stores} Organizations[/]";
  
            var circle = template.createChild(am4core.Circle);
            circle.radius = 10;
            circle.fillOpacity = 0.7;
            circle.fill = am4core.color("#0c4aa3");
            circle.verticalCenter = "middle";
            circle.horizontalCenter = "middle";
            circle.nonScaling = true;
  
            var label = template.createChild(am4core.Label);
            label.text = "{stores}";
            label.fill = am4core.color("#fff");
            label.verticalCenter = "middle";
            label.horizontalCenter = "middle";
            label.nonScaling = true;
  
            var heat = series.heatRules.push({
              target: circle,
              property: "radius",
              min: 10,
              max: 30
            });
  
            // Set up drill-down
            series.mapImages.template.events.on("hit", function (ev) {
  
              // Determine what we've clicked on
              var data = ev.target.dataItem.dataContext;
  
              // No id? Individual store - nothing to drill down to further
              if (!data['target']) {
                return;
              }
  
              // Create actual series if it hasn't been yet created
              if (!regionalSeries[data['target']].series) {
                regionalSeries[data['target']].series = createSeries("count");
                regionalSeries[data['target']].series.data = data['markerData'];
              }  
  
              // Show new targert series
              currentSeries = regionalSeries[data['target']].series;
              currentSeries.show();
            });
  
            return series;
          }
  
          var regionalSeries = {};
          var currentSeries;
  
          function setupStores(data) {
            // Init country-level series
            regionalSeries['US'] = {
              markerData: [],
              series: createSeries("stores")
            };
  
  
            // Set current series
            currentSeries = regionalSeries['US'].series;
  
            // Process data
            am4core.array.each(data, function (store1) {
  
              // Get store data
              var store = {
                state: store1['state_abbreviation'],
                count: am4core.type.toNumber(store1['count'])
              };
  
              // Process state-level data
              if (regionalSeries[store['state']] == undefined) {
                var statePolygon = polygonSeries.getPolygonById("US-" + store['state']);
                if (statePolygon) {
  
                  // Add state data
                  regionalSeries[store['state']] = {
                    target: store['state'],
                    type: "state",
                    name: statePolygon.dataItem.dataContext['name'],
                    count: store['count'],
                    stores: store['count'],
                    lat: statePolygon.visualLatitude,
                    long: statePolygon.visualLongitude,
                    state: store['state'],
                    markerData: []
                  };
                  regionalSeries['US'].markerData.push(regionalSeries[store['state']]);
  
                }
                else {
                  // State not found
                  return;
                }
              }
              else {
                regionalSeries[store['state']].stores++;
                regionalSeries[store['state']].count += store['count'];
              }
  
              // Process city-level data
              if (regionalSeries[store['city']] == undefined) {
                regionalSeries[store['city']] = {
                  count: store['count'],
                  state: store['state'],
                  markerData: []
                };
                regionalSeries[store['state']].markerData.push(regionalSeries[store['city']]);
              }
              else {
                regionalSeries[store['city']].stores++;
                regionalSeries[store['city']].count += store['count'];
              }
  
              // Process individual store
              regionalSeries[store['city']].markerData.push({
                count: store['count'],
                state: store['state']
              });
  
            });
            regionalSeries['US'].series.data = regionalSeries['US'].markerData;
          }
  
        }); // end am4core.ready()
  
      }, 500);
    },
    (error) => {
      this.loader.recentlyConnectedOrganizations = false;
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });

    this.DashboardService.getStatewiseCountForAdmin({ 'type': 'byUsers' })
    .then((data) => {
      let org_data = data['data'];
      this.loader.stateWiseOrganizationsAndUsers = false;
   
      setTimeout(function () {

        am4core.ready(function () {
          // Create map instance
          var chart = am4core.create("map_chart_user", am4maps.MapChart);
          am4core.addLicense("CH236795548");
          chart.logo.hidden = true;
          chart.maxZoomLevel = 64;
          chart.responsive.enabled = true;
  
          // Set map definition
          chart.geodata = am4geodata_usaLow;
  
          // Set projection
          // chart.projection = new am4maps.projections.AlbersUsa();
  
          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
          polygonSeries.useGeodata = true;
          polygonSeries.calculateVisualCenter = true;
  
          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.tooltipText = "{name}";
          polygonTemplate.fill = am4core.color("#209cb7");
  
          // Load data when map polygons are ready
          chart.events.on("ready", loadStores);
  
          // Loads store data
          function loadStores() {
            var loader = new am4core.DataSource();
            loader.data = org_data;
            setupStores(loader.data);  
            loader.load();
          }
  
          // Creates a series
          function createSeries(heatfield) {
            var series = chart.series.push(new am4maps.MapImageSeries());
            series.dataFields.value = heatfield;
  
            var template = series.mapImages.template;
            template.verticalCenter = "middle";
            template.horizontalCenter = "middle";
            template.propertyFields.latitude = "lat";
            template.propertyFields.longitude = "long";
            template.tooltipText = "{name}:\n[bold]{stores} Users[/]";
  
            var circle = template.createChild(am4core.Circle);
            circle.radius = 10;
            circle.fillOpacity = 0.7;
            circle.fill = am4core.color("#0c4aa3");
            circle.verticalCenter = "middle";
            circle.horizontalCenter = "middle";
            circle.nonScaling = true;
  
            var label = template.createChild(am4core.Label);
            label.text = "{stores}";
            label.fill = am4core.color("#fff");
            label.verticalCenter = "middle";
            label.horizontalCenter = "middle";
            label.nonScaling = true;
  
            var heat = series.heatRules.push({
              target: circle,
              property: "radius",
              min: 10,
              max: 30
            });
  
            // Set up drill-down
            series.mapImages.template.events.on("hit", function (ev) {
  
              // Determine what we've clicked on
              var data = ev.target.dataItem.dataContext;
  
              // No id? Individual store - nothing to drill down to further
              if (!data['target']) {
                return;
              }
  
              // Create actual series if it hasn't been yet created
              if (!regionalSeries[data['target']].series) {
                regionalSeries[data['target']].series = createSeries("count");
                regionalSeries[data['target']].series.data = data['markerData'];
              }  
  
              // Show new targert series
              currentSeries = regionalSeries[data['target']].series;
              currentSeries.show();
            });
  
            return series;
          }
  
          var regionalSeries = {};
          var currentSeries;
  
          function setupStores(data) {
            // Init country-level series
            regionalSeries['US'] = {
              markerData: [],
              series: createSeries("stores")
            };
  
  
            // Set current series
            currentSeries = regionalSeries['US'].series;
  
            // Process data
            am4core.array.each(data, function (store1) {
  
              // Get store data
              var store = {
                state: store1['state_abbreviation'],
                count: am4core.type.toNumber(store1['count'])
              };
  
              // Process state-level data
              if (regionalSeries[store['state']] == undefined) {
                var statePolygon = polygonSeries.getPolygonById("US-" + store['state']);
                if (statePolygon) {
  
                  // Add state data
                  regionalSeries[store['state']] = {
                    target: store['state'],
                    type: "state",
                    name: statePolygon.dataItem.dataContext['name'],
                    count: store['count'],
                    stores: store['count'],
                    lat: statePolygon.visualLatitude,
                    long: statePolygon.visualLongitude,
                    state: store['state'],
                    markerData: []
                  };
                  regionalSeries['US'].markerData.push(regionalSeries[store['state']]);
  
                }
                else {
                  // State not found
                  return;
                }
              }
              else {
                regionalSeries[store['state']].stores++;
                regionalSeries[store['state']].count += store['count'];
              }
  
              // Process city-level data
              if (regionalSeries[store['city']] == undefined) {
                regionalSeries[store['city']] = {
                  count: store['count'],
                  state: store['state'],
                  markerData: []
                };
                regionalSeries[store['state']].markerData.push(regionalSeries[store['city']]);
              }
              else {
                regionalSeries[store['city']].stores++;
                regionalSeries[store['city']].count += store['count'];
              }
  
              // Process individual store
              regionalSeries[store['city']].markerData.push({
                count: store['count'],
                state: store['state']
              });
  
            });
            regionalSeries['US'].series.data = regionalSeries['US'].markerData;
          }
  
        }); // end am4core.ready()
  
      }, 500);
    },
    (error) => {
      this.loader.recentlyConnectedOrganizations = false;
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
  }

  
  backToOrg(flag: boolean){
    localStorage.setItem("BACKTOORGBTN", flag.toString())
     }

}

