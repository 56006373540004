import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { InstituteUsersService } from '../services/institute-users.service';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.css'
})
export class SubscriptionComponent {

  id;
  form: UntypedFormGroup;
  duplicate_name: boolean = false;
  currentDate = new Date();
  instituteList = [];
  deptList = [];
  deptListFilterData = [];
  currentSelectedInstitute: any;
  instituteListFilterData: any;
  institute_id = this.globals.selectedInstitute?this.globals.selectedInstitute.id:null;
  subscriptionExpiryDate: any = new Date();
  toggleOrganizationDropdown: boolean = false;
  defaultItem = {
    "id": null,
    "institute_name": "Select Organization",
    "is_active": 1,
    "provider_id": ""
  };

  defaultDept ={
    "id": null,
    "department": "Select a Division",
  }

  minDate:any = this.getTomorrowDate();


  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public instituteUsersService: InstituteUsersService, public subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.globals.isLoading = true;
    this.subscriptionExpiryDate = this.getTomorrowDate();
    this.globals.breadcrumbs = ['Manage', 'Subscriptions', ''];

    this.institute_id =
    this.globals.selected_institute != null
      ? this.globals.selected_institute.id
      : null;
  if (this.institute_id == null) {
    this.globals.isLoading = false;
    this.globals.dialogOpened = true;
}


    this.getSubscriptionExpiryDurationDays();
    if(this.institute_id!==null && this.institute_id!=='' && this.institute_id!==undefined) {
      this.toggleOrganizationDropdown = true;
    }
    this.form = this.formBuilder.group({
      institute_id: [this.institute_id,[Validators.required]],
      subscription_number: ['', [Validators.required]],
      department_id: [''],
      comment: [''],
      subscription_order_id: ['', [Validators.required,noWhitespaceValidator(),Validators.pattern('^[a-zA-Z\\d@_-]+$')]],
      expiry_date: ['', [Validators.required]],
      is_active: [1]
    });

    this.currentSelectedInstitute = this.institute_id;
    this.getDepartments();

    this.id = (this.route.snapshot.paramMap.get('id')) ? this.route.snapshot.paramMap.get('id') : 0;
    this.getInstitutes()
    if (this.id != 0) {
      this.id = window.atob(this.id);
      this.globals.isLoading = true;
      this.subscriptionService.getById(this.id)
        .then((data) => {
          this.globals.isLoading = false;

          var fields = data['data'];
          let expiry_date = new Date(fields['license_expire']);
          this.form.patchValue({
            institute_id: fields['institute_id'],
            subscription_number: fields['total_licenses'],
            subscription_order_id: fields['subscription_order_id'],
            department_id: fields['department_id'],
            comment: fields['comment'],
            expiry_date: fields['license_expire'] ? new Date(Date.UTC(expiry_date.getFullYear(), expiry_date.getMonth(), expiry_date.getDate())  + 'T00:00:00') : null,
            is_active: fields['is_active']
          });
        },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          });
    }
  }

  getSubscriptionExpiryDurationDays() {
    this.subscriptionService.getConfigValueByKey("SubscriptionExpiryDuration")
      .then((data: any) => {
        let resultExpiryDuration = Number(data['data'][0]['display_text']);
        if (resultExpiryDuration > 0) {
          this.subscriptionExpiryDate.setDate(this.subscriptionExpiryDate.getDate() + resultExpiryDuration);
        } else {
          // If the SubscripitonExpiryDate is not greater than 0 then it will update the max date of the kendo time picker to year 2100.
          this.subscriptionExpiryDate = new Date(2100, 11, 31);
        }
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  getInstitutes() {
    this.instituteUsersService.getInstituteList()
      .then((data: any) => {
       
        this.instituteList = data['date']
       
        this.globals.isLoading = false;
        this.instituteList = data['data'];
        this.instituteListFilterData = data['data'];
        this.instituteUsersService = data['data'];
       
  
       
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  getDepartments() {
    this.globals.isLoading = true;
    this.subscriptionService.getDepartments(this.currentSelectedInstitute)
      .then((data: any) => {
        this.deptList = data['data']   
        this.globals.isLoading = false;
        this.deptListFilterData = data['data'];      
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

 

  instituteFilter(value) {
    
    this.instituteList = this.instituteListFilterData.filter((s: { institute_name: string; }) => s.institute_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  deptFilter(value) {
   
    this.deptList = this.deptListFilterData.filter((s: { department: string; }) => s.department.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  


  addUpdate() {
    this.form.markAllAsTouched();
    const institute_id = this.form.get('institute_id').value;
    const subscription_number = this.form.get('subscription_number').value;
    const subscription_order_id = this.form.get('subscription_order_id').value;
    const department_id = this.form.get('department_id').value;
    const comment = this.form.get('comment').value;
    const expiryDate = this.form.get('expiry_date').value;
    const is_active = this.form.get('is_active').value;

    const formatDate = (date: string) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;

    };
    const formattedExpiryDate = formatDate(expiryDate);
    var entity = {
      institute_id: institute_id,
      subscription_number: subscription_number,
      subscription_order_id: subscription_order_id,
      department_id: department_id,
      comment: comment,
      expiry_date: formattedExpiryDate,
      is_active: is_active
    }

    if (this.form.valid) {
      this.globals.isLoading = true;
      if (this.id == 0) {
        this.add(entity);
      } else {
        this.update(this.form.value, this.id);
      }
    }
  }

  add(entity) {
    entity.is_active = (entity.is_active) ? 1 : 0;
   
    this.subscriptionService.add(entity)
      .then((data) => {
        this.globals.isLoading = false;
        this.globals.sweetAlert('success', 'Subscription Pack Added', 'Subscription pack has been added successfully.');
        this.router.navigate(['/manage/subscription/list']);
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.isLoading = false;
          if(error.error.code == 303){
            this.globals.sweetAlert('error','Order ID Exists','Order ID has already been taken.');
          }
          this.globals.errorSweetAlert();

        });
  }


  update(entity, id) {
    entity.is_active = (entity.is_active) ? 1 : 0;
    this.subscriptionService.update(entity, id)
      .then((data) => {
        this.globals.isLoading = false;
        this.globals.sweetAlert('success', 'Subscription Pack Updated', 'Subscription pack has been updated successfully.');
        this.router.navigate(['/manage/subscription/list']);
      },
        (error) => {
          this.globals.isLoading = false;

          this.globals.errorSweetAlert();

        });
  }

  onOrgChange(value){
    this.currentSelectedInstitute = value;
    this.getDepartments();
    const subControl = this.form.get('department_id');
    const validators1 = subControl.validator;
    subControl.clearValidators();
    subControl.updateValueAndValidity({ emitEvent: false });
    subControl.setValue(null, { emitEvent: false });
    subControl.setErrors(null); 
    subControl.markAsPristine({ onlySelf: true });
    subControl.setValidators(validators1);
    subControl.updateValueAndValidity({ emitEvent: false });
    subControl.markAsUntouched({ onlySelf: true });
  
  
    const divControl = this.form.get('subscription_order_id');
    const validators = divControl.validator;
    divControl.clearValidators();
    divControl.updateValueAndValidity({ emitEvent: false });
    divControl.setValue(null, { emitEvent: false });
    divControl.setErrors(null); 
    divControl.markAsPristine({ onlySelf: true });
    divControl.setValidators(validators);
    divControl.updateValueAndValidity({ emitEvent: false });
    divControl.markAsUntouched({ onlySelf: true });
  
  
    const totalLicControl = this.form.get('subscription_number');
    const validators2 = totalLicControl.validator;
    totalLicControl.clearValidators();
    totalLicControl.updateValueAndValidity({ emitEvent: false });
    totalLicControl.setValue(null, { emitEvent: false });
    totalLicControl.setErrors(null); 
    totalLicControl.markAsPristine({ onlySelf: true });
    totalLicControl.setValidators(validators2);
    totalLicControl.updateValueAndValidity({ emitEvent: false });
    totalLicControl.markAsUntouched({ onlySelf: true });
  
    const commentControl = this.form.get('comment');
    const validators3 = commentControl.validator;
    commentControl.clearValidators();
    commentControl.updateValueAndValidity({ emitEvent: false });
    commentControl.setValue(null, { emitEvent: false });
    commentControl.setErrors(null); 
    commentControl.markAsPristine({ onlySelf: true });
    commentControl.setValidators(validators3);
    commentControl.updateValueAndValidity({ emitEvent: false });
    commentControl.markAsUntouched({ onlySelf: true });

    const expDateControl = this.form.get('expiry_date');
    const validators4 = expDateControl.validator;
    expDateControl.clearValidators();
    expDateControl.updateValueAndValidity({ emitEvent: false });
    expDateControl.setValue(null, { emitEvent: false });
    expDateControl.setErrors(null); 
    expDateControl.markAsPristine({ onlySelf: true });
    expDateControl.setValidators(validators4);
    expDateControl.updateValueAndValidity({ emitEvent: false });
    expDateControl.markAsUntouched({ onlySelf: true });
    
  }

  getTomorrowDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    
    // Set time to 00:00:00
    tomorrow.setHours(0, 0, 0, 0);
    
    return tomorrow;
    
  }

}


export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': 'value is only whitespace' };
  };
}
