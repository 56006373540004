import { Injectable } from '@angular/core';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { dateFieldName } from '@progress/kendo-angular-intl';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private globals: Globals, private CookieService: CookieService, private router: Router) { }

  checkLogin(loginEntity) {
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/login', loginEntity)
        .toPromise()
        .then(
          res => {
            this.globals.isLoading = true;
            let result = res['data'];
            if (result && result['access_token']) {
              this.globals.authData = '';
          
              this.CookieService.delete('selected_institute', '/', this.globals.CookieDomainUrl);
              this.CookieService.delete('expires_at', '/', this.globals.CookieDomainUrl);
              this.CookieService.delete('access_token', '/', this.globals.CookieDomainUrl);
              this.CookieService.set('access_token', result['access_token'], 1, '/', this.globals.CookieDomainUrl);
              this.CookieService.set('expires_at', window.btoa(result['expires_at']), 1, '/', this.globals.CookieDomainUrl);

              this.CookieService.set('token_type', window.btoa(result['token_type']), 365, '/', this.globals.CookieDomainUrl);
            }
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkAMSNLogin(token) {
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/client-login', token)
        .toPromise()
        .then(
          res => {
            this.globals.isLoading = true;
            let result = res['data'];
            if (result && result['access_token']) {
              this.globals.authData = '';
           
              this.CookieService.delete('expires_at', '/', this.globals.CookieDomainUrl);
              this.CookieService.delete('access_token', '/', this.globals.CookieDomainUrl);
              this.CookieService.set('access_token', result['access_token'], 1, '/', this.globals.CookieDomainUrl);
              this.CookieService.set('expires_at', window.btoa(result['expires_at']), 1, '/', this.globals.CookieDomainUrl);
              this.CookieService.set('token_type', window.btoa(result['token_type']), 365, '/', this.globals.CookieDomainUrl);
            }
            setTimeout(() => {
              resolve(res);
            }, 1000)
          },
          msg => {
          
            this.router.navigate(['/authentication-failed']);
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkOESSAdminLogin(token) {
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/master-login', token)
        .toPromise()
        .then(
          res => {
            this.globals.isLoading = true;
            let result = res['data'];
            if (result && result['access_token']) {
              this.globals.authData = '';
              this.CookieService.delete('expires_at', '/', this.globals.CookieDomainUrl);
              this.CookieService.delete('access_token', '/', this.globals.CookieDomainUrl);
              this.CookieService.set('access_token', result['access_token'], 1, '/', this.globals.CookieDomainUrl);
              this.CookieService.set('expires_at', window.btoa(result['expires_at']), 1, '/', this.globals.CookieDomainUrl);
              this.CookieService.set('token_type', window.btoa(result['token_type']), 365, '/', this.globals.CookieDomainUrl);
            }
            resolve(res);
          },
          msg => {
        
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAuthUser() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/product-user')
        .toPromise()
        .then(
          res => {
            let result = res['data'];

          
            localStorage.setItem('auth_user', window.btoa(JSON.stringify(result)));
            this.CookieService.set('user_token', result['id'], 1, '/', this.globals.CookieDomainUrl);
            this.CookieService.set('auth_user', window.btoa(JSON.stringify(result)), 365, '/', this.globals.CookieDomainUrl);
            this.globals.authData = result;

            if (this.globals.authData.list.length > 1) {
              let check = 0;
              let inactive_check = 0;
              let total_check = 0;
              let active_check = 0;
              let index = 0;
              for (let i = 0; i < this.globals.authData.list.length; i++) {
                if (
                  this.globals.authData.list[i].role_value == 1 ||
                  this.globals.authData.list[i].role_value == 2
                ) {
                  if (
                    this.globals.authData.list[i].institute != '' &&
                    this.globals.authData.list[i].institute.is_active == 0
                  ) {
                  } else {
                    check++;
                    this.globals.authData.list[i].index_number = i;
                    this.CookieService.set(
                      'institute_details',
                      window.btoa(
                        JSON.stringify(this.globals.authData.list[i])
                      ),
                      365,
                      '/',
                      this.globals.CookieDomainUrl
                    );
                    this.globals.institute_details =
                      this.globals.authData.list[i];
                    if (
                      this.globals.institute_details.role_value == 1 ||
                      this.globals.institute_details.role_value == 2
                    ) {
                      window.location.href =
                        this.globals.baseUrl + '/overview-dashboard';
                    } else {
                      this.globals.selected_institute =
                        this.globals.institute_details.institute;
                      this.CookieService.set(
                        'selected_institute',
                        window.btoa(
                          JSON.stringify(
                            this.globals.institute_details.institute
                          )
                        ),
                        365,
                        '/',
                        this.globals.CookieDomainUrl
                      );

                      this.globals.saveRecentlyInstitutes(
                        this.globals.institute_details.institute.id
                      );

                      window.location.href =
                        this.globals.baseUrl + '/dashboard';
                    }
                    //break;
                  }
                }
                if (this.globals.authData.list[i].institute != '') {
                  if (this.globals.authData.list[i].institute.is_active == 0) {
                    inactive_check++;
                  } else {
                    index = this.globals.authData.list.reduce((minIdx, current, currentIndex, array) => {
                      return (current.role_value < array[minIdx].role_value) ? currentIndex : minIdx;
                    }, 0);
                  
                    active_check++;
                  }
                  total_check++;
                }
              }
              if (
                active_check == 1 &&
                total_check == this.globals.authData.list.length
              ) {
                let i = index;
                this.globals.authData.list[i].index_number = i;
                this.CookieService.set(
                  'institute_details',
                  window.btoa(JSON.stringify(this.globals.authData.list[i])),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );
                this.globals.institute_details = this.globals.authData.list[i];
                if (
                  this.globals.institute_details.role_value == 1 ||
                  this.globals.institute_details.role_value == 2
                ) {
                  window.location.href =
                    this.globals.baseUrl + '/overview-dashboard';
                } else {
                  this.globals.selected_institute =
                    this.globals.institute_details.institute;
                  this.CookieService.set(
                    'selected_institute',
                    window.btoa(
                      JSON.stringify(this.globals.institute_details.institute)
                    ),
                    365,
                    '/',
                    this.globals.CookieDomainUrl
                  );

                  this.globals.saveRecentlyInstitutes(
                    this.globals.institute_details.institute.id
                  );

                  if (this.globals.institute_details.role_value == 6) {
                    window.location.href = this.globals.baseUrl + '/manage/assigned-user-subscription/list';

                    return;
                  }
                  window.location.href = this.globals.baseUrl + '/dashboard';
                }
              } else {
                if (inactive_check == total_check) {
                  this.router.navigate(['/landing-dashboard']);
                 
                } else if (check == 0) {
                  let i = index;
                  this.globals.authData.list[i].index_number = i;
                  this.CookieService.set(
                    'institute_details',
                    window.btoa(JSON.stringify(this.globals.authData.list[i])),
                    365,
                    '/',
                    this.globals.CookieDomainUrl
                  );
                  this.globals.institute_details =
                    this.globals.authData.list[i];
                  if (
                    this.globals.institute_details.role_value == 1 ||
                    this.globals.institute_details.role_value == 2
                  ) {
                    window.location.href =
                      this.globals.baseUrl + '/overview-dashboard';
                  } else {
                    this.globals.selected_institute =
                      this.globals.institute_details.institute;
                    this.CookieService.set(
                      'selected_institute',
                      window.btoa(
                        JSON.stringify(this.globals.institute_details.institute)
                      ),
                      365,
                      '/',
                      this.globals.CookieDomainUrl
                    );

                    this.globals.saveRecentlyInstitutes(
                      this.globals.institute_details.institute.id
                    );


                    if (this.globals.institute_details.role_value == 6) {
                      window.location.href = this.globals.baseUrl + '/manage/assigned-user-subscription/list';

                      return;
                    }
                    window.location.href = this.globals.baseUrl + '/dashboard';
                  }
               
                }
              }
            } else if (this.globals.authData.list.length == 1) {
              if (
                this.globals.authData.list[0].institute != '' &&
                this.globals.authData.list[0].institute.is_active == 0
              ) {
                this.router.navigate(['/landing-dashboard']);
               
              } else {

                this.globals.authData.list[0].index_number = 0;
                this.CookieService.set(
                  'institute_details',
                  window.btoa(JSON.stringify(this.globals.authData.list[0])),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );
                this.globals.institute_details = this.globals.authData.list[0];
                if (
                  this.globals.institute_details.role_value == 1 ||
                  this.globals.institute_details.role_value == 2
                ) {
                  window.location.href =
                    this.globals.baseUrl + '/overview-dashboard';
                } else {
                  this.globals.selected_institute =
                    this.globals.institute_details.institute;
                  this.CookieService.set(
                    'selected_institute',
                    window.btoa(
                      JSON.stringify(this.globals.institute_details.institute)
                    ),
                    365,
                    '/',
                    this.globals.CookieDomainUrl
                  );

                  this.globals.saveRecentlyInstitutes(
                    this.globals.institute_details.institute.id
                  );

                  //here redirecting to div head


                  if (this.globals.institute_details.role_value == 6) {
                    window.location.href = this.globals.baseUrl + '/manage/assigned-user-subscription/list';

                    return;
                  } else if (this.globals.institute_details.role_value == 3) {
                    window.location.href = this.globals.baseUrl + '/dashboard';
                  
                  }
                }
              }
            } else if (this.globals.authData.list.length == 0) {
              this.router.navigate(['/landing-dashboard']);
        
            }
            resolve(result);
           
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }


  getUserInsittuteData() {
    this.globals.isLoading = true
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/product-user')
        .toPromise()
        .then(
          res => {
            let result = res['data'];

            localStorage.setItem('auth_user', window.btoa(JSON.stringify(result)));
            this.CookieService.set('user_token', result['id'], 1, '/', this.globals.CookieDomainUrl);
            this.CookieService.set('auth_user', window.btoa(JSON.stringify(result)), 365, '/', this.globals.CookieDomainUrl);


            const roleHierarchy = [1, 2, 3, 6, 4, 5]; 

            const getRoleIndex = (roleValue) => {
              return roleHierarchy.indexOf(roleValue);
            };
            
            const filteredInstitutes = Object.values(
              result.list.reduce((acc, item) => {
                const institute = item.institute;
                const currentRole = item.role_value;
            
                if (
                  !acc[institute.id] || 
                  getRoleIndex(currentRole) < getRoleIndex(acc[institute.id].role_value)
                ) {
                  acc[institute.id] = item;
                }
            
                return acc;
              }, {})
            );
            
           


        
      
            // Filter items where role_value is 6
            const validRoleValue6Items = filteredInstitutes.filter((e:any) => e.role_value === 6);
        
            // Count unique institutes in the full list (before filtering role_value)
            const uniqueInstituteCount = new Set(filteredInstitutes.map((e:any) => e.institute.id)).size;
        
        
            // Determine if showSelectView should be true
            if (validRoleValue6Items.length > 0 && uniqueInstituteCount > 1) {
              this.globals.showSelectView = true;
            } else {
              this.globals.showSelectView = false;
            }
        
            // Final check if only one item exists
            if (filteredInstitutes.length <= 1) {
              this.globals.showSelectView = false;
             
            }

            this.globals.isLoading = false
         
            resolve(filteredInstitutes)
          
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAuthUserRole() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/product-user')
        .toPromise()
        .then(
          res => {


            let result = res['data'];
            localStorage.setItem('auth_user', window.btoa(JSON.stringify(result)));

            const selected_institute = this.CookieService.get('selected_institute') ? JSON.parse(window.atob(this.CookieService.get('selected_institute'))) : null;
            const current_role_value = this.CookieService.get('current_role_value') ? JSON.parse(window.atob(this.CookieService.get('current_role_value'))) : null;

            if (selected_institute && current_role_value && [1, 2, 3, 6].includes(current_role_value)) {
              const institute = result.list.filter((e) => {
                return e.institute.id == selected_institute.id;
              })
             
              const duplicateInstituteId = this.globals.authData.list.some((e, index, array) =>
                array.findIndex(item => item.institute.id === e.institute.id) !== index
              );
              if (duplicateInstituteId) {
                var index = this.globals.authData.list.reduce((minIdx, current, currentIndex, array) => {
                  return (current.role_value < array[minIdx].role_value) ? currentIndex : minIdx;
                }, 0);
                if (institute.length > 0) {
                  this.globals.highestUserRole = institute[index]?.role_name;
                  this.globals.highestUserData = institute[index];
                }
                else if (result.list[index].role_value == 2 || result.list[index].role_value == 1) {
                  this.globals.highestUserRole = result.list[index]?.role_name;
                  this.globals.highestUserData = result.list[index];
                }
              }
              else {
                if (institute.length > 0) {
                  this.globals.highestUserRole = institute[0]?.role_name;
                  this.globals.highestUserData = institute[0];
                }
                else if (result.list[0].role_value == 2 || result.list[0].role_value == 1) {
                  this.globals.highestUserRole = result.list[0]?.role_name;
                  this.globals.highestUserData = result.list[0];
                }
              }


            }

            else{

              const roleHierarchy = [1, 2, 3, 6, 4, 5];
              let highestPriorityRole = null;
              let highestRoleData = null;
              let roleName = null
             

              result.list.forEach((value) => {
                
                if (
                  highestPriorityRole === null ||
                  roleHierarchy.indexOf(value.role_value) < roleHierarchy.indexOf(highestPriorityRole)
                ) {
                  highestPriorityRole = value.role_value;
                  roleName = value.role_name

                  highestRoleData = value;


                }
              });

              this.globals.highestUserRole = roleName;
              this.globals.highestUserData = highestRoleData;



              localStorage.setItem('auth_user', window.btoa(JSON.stringify(result)));


              if (highestRoleData) {
                this.CookieService.set(
                  'institute_details',
                  window.btoa(JSON.stringify(highestRoleData)),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );
              }
            

            }
            
           

         

            resolve(result);
            //}
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  refreshAuthDataForDivHead() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/product-user')
        .toPromise()
        .then(
          res => {
            let result = res['data'];
          
            localStorage.setItem('auth_user', window.btoa(JSON.stringify(result)));
            this.CookieService.set('user_token', result['id'], 1, '/', this.globals.CookieDomainUrl);
            this.CookieService.set('auth_user', window.btoa(JSON.stringify(result)), 365, '/', this.globals.CookieDomainUrl);
            this.globals.authData = result;

            if (this.globals.authData.list.length > 1) {
              let check = 0;
              let inactive_check = 0;
              let total_check = 0;
              let active_check = 0;
              let index = 0;
              for (let i = 0; i < this.globals.authData.list.length; i++) {


                this.globals.authData.list[i].index_number = i;
                this.CookieService.set(
                  'institute_details',
                  window.btoa(
                    JSON.stringify(this.globals.authData.list[i])
                  ),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );
                this.globals.institute_details =
                  this.globals.authData.list[i];

                this.globals.selected_institute =
                  this.globals.institute_details.institute;
                this.CookieService.set(
                  'selected_institute',
                  window.btoa(
                    JSON.stringify(
                      this.globals.institute_details.institute
                    )
                  ),
                  365,
                  '/',
                  this.globals.CookieDomainUrl
                );

                this.globals.saveRecentlyInstitutes(
                  this.globals.institute_details.institute.id
                );


              }

            }


            resolve(result);
          
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }
  getAuthUserInfo() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/user-info')
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  logout() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/logout')
        .toPromise()
        .then(
          res => {
            this.globals.authData = '';
           
            localStorage.removeItem('auth_user');
            this.CookieService.delete('auth_user', '/', this.globals.CookieDomainUrl);
           
            localStorage.removeItem('user_role_permission');
           
            this.CookieService.delete('selected_institute', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('expires_at', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('access_token', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('token_type', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('institute_details', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('user_token', '/', this.globals.CookieDomainUrl);
            this.globals.permissions = {};
            resolve(res);
          },
          msg => {
           
            this.globals.authData = '';
           
            this.CookieService.delete('auth_user', '/', this.globals.CookieDomainUrl);
            localStorage.removeItem('user_role_permission');
            
            this.CookieService.delete('selected_institute', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('expires_at', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('access_token', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('token_type', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('institute_details', '/', this.globals.CookieDomainUrl);
            this.CookieService.delete('user_token', '/', this.globals.CookieDomainUrl);
            this.globals.permissions = {};
            reject(msg);
          }
        );
    });
    return promise;
  }

  isLoggedIn() {
    let expires_at = window.atob(this.CookieService.get('expires_at'));
    let isExpired = expires_at ? false : true;

    if (isExpired) {
      this.globals.authData = undefined;
    }
    return !isExpired;


  }

  getIP() {
    let promise = new Promise((resolve, reject) => {
      this.http.get('https://api.ipify.org/?format=json')
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getLastUpdatedDateOfRolePermission() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/permission/latest-updated')
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllRolePermissionData(permission) {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/permission/role-permission')
        .toPromise()
        .then(
          res => {
            let last_updated = res['data']['last_updated'];
            let permission_data = res['data']['permission_data'];
            localStorage.setItem('last_updated_date_role_permission', window.btoa(last_updated));
            localStorage.setItem('all_role_permission', window.btoa(JSON.stringify(permission_data)));
            let active_license = (this.globals.institute_details && (this.globals.institute_details.active_license > 0)) ? 0 : 0;
            if (this.globals.institute_details) {
              let retrievedObject1Arr = permission_data.filter((item) => {
                if (item.role_value == this.globals.institute_details.role_value && item.is_accessible_after_expire == active_license) {
                  return item;
                }
              });

              let retrievedObject1 = retrievedObject1Arr.length > 0 ? retrievedObject1Arr[0].permission_list : [];

              let user_role_permission = {};
              let index;
              this.globals.permissionText.forEach(function (menu, key) {
                index = retrievedObject1.findIndex(retrievedObject1 => (retrievedObject1.slug === menu.key && retrievedObject1.has_access == 1));
                if (index != -1) {
                  user_role_permission[menu.key] = true;
                } else {
                  user_role_permission[menu.key] = false;
                }
              });
              localStorage.setItem('user_role_permission', window.btoa(JSON.stringify(user_role_permission)));
              this.globals.permissions = user_role_permission;
            }

            if (!this.globals.permissions[permission]) {
              this.router.navigate(['/permission-not-found']);
              return false;
            }

            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllRolePermissionData1() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + '/permission/role-permission')
        .toPromise()
        .then(
          res => {
            let last_updated = res['data']['last_updated'];
            let permission_data = res['data']['permission_data'];
            localStorage.setItem('last_updated_date_role_permission', window.btoa(last_updated));
            localStorage.setItem('all_role_permission', window.btoa(JSON.stringify(permission_data)));
            let active_license = (this.globals.institute_details && (this.globals.institute_details.active_license > 0)) ? 0 : 0;
            if (this.globals.institute_details) {
              let retrievedObject1Arr = permission_data.filter((item) => {
                if (item.role_value == this.globals.institute_details.role_value && item.is_accessible_after_expire == active_license) {
                  return item;
                }
              });

              let retrievedObject1 = retrievedObject1Arr.length > 0 ? retrievedObject1Arr[0].permission_list : [];

              let user_role_permission = {};
              let index;
              this.globals.permissionText.forEach(function (menu, key) {
                index = retrievedObject1.findIndex(retrievedObject1 => (retrievedObject1.slug === menu.key && retrievedObject1.has_access == 1));
                if (index != -1) {
                  user_role_permission[menu.key] = true;
                } else {
                  user_role_permission[menu.key] = false;
                }
              });
              localStorage.setItem('user_role_permission', window.btoa(JSON.stringify(user_role_permission)));
              this.globals.permissions = user_role_permission;

            }

            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  convertDate(d) {
    var mm = (d.getMonth() + 1),
      dd = d.getDate(),
      yyyy = d.getFullYear(),
      hour = d.getHours(),
      min = d.getMinutes(),
      sec = d.getSeconds();
    return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]) + 'T' + hour + ':' + min + ':' + sec + 'Z';
  }

  getUserRoleList(data) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + '/user-details-auth', data)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

}
