import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { CouponService } from '../services/coupon.service';

function noNegativeValues(control: AbstractControl) {
  if (control.value < 0) {
    return { negative: true };
  }
  return null;
}

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrl: './coupon.component.css'
})
export class CouponComponent {
  id;
  form: UntypedFormGroup;
  duplicate_name: boolean = false;
  discount_type: any = '261';
  discount_type_value: any = false;
  currentDate = new Date();

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,private formBuilder: UntypedFormBuilder, public couponService: CouponService) { }

  ngOnInit(): void {
    this.globals.breadcrumbs = ['Manage','Types',''];
    this.form = this.formBuilder.group({
      name : ['',[Validators.required,noWhitespaceValidator()]],
      description: [''],
      coupon_type_id: ["261"],

      pervalue: ['',[Validators.required]],  
      amntvalue: ['',[Validators.required,Validators.min(1), noNegativeValues]],
      expiry_date: ['',[Validators.required]],
      is_active: [1]
    });

    

    this.id = (this.route.snapshot.paramMap.get('id')) ? this.route.snapshot.paramMap.get('id') : 0;

    if (this.id != 0) {
      this.id = window.atob(this.id);
      this.globals.isLoading = true;
      this.couponService.getById(this.id)
      .then((data) => {
                this.globals.isLoading = false;

        var fields = data['data'];
        this.form.patchValue({
          name : fields['name'],
          description: fields['description'],
          coupon_type_id: fields['coupon_type_id'].toString(),
          pervalue: fields['value'],
          amntvalue: parseInt(fields['value']),
          expiry_date: fields['expiry_date'] ? new Date(fields['expiry_date'] + 'T00:00:00') : null,
          is_active: fields['is_active']
        });

        this.discount_type =fields['coupon_type_id'];

        this.form.get('name').disable();
        this.form.get('coupon_type_id').disable();

        this.form.get('amntvalue').disable();
        this.form.get('pervalue').disable();

      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      });
  }
}
    
  

  public onChange(value: string): void {
    this.form.patchValue({

      pervalue:null,  
      amntvalue: null,

    });
      this.discount_type = value;
   
  }

  addUpdate() {
    if(this.discount_type == "261"){
      this.form.patchValue({
        pervalue :  this.form.get('amntvalue').value
      })
    }
    else{
      this.form.patchValue({
        amntvalue :  this.form.get('pervalue').value
      })
    }
    this.form.markAllAsTouched();
    const name = this.form.get('name').value;
    const description = this.form.get('description').value;
    const value =  this.discount_type == "260" ? this.form.get('pervalue').value : this.form.get('amntvalue').value;
   
    const expiryDate = this.form.get('expiry_date').value;
    const couponTypeId = this.discount_type;
    const is_active = this.form.get('is_active').value;
  
    const formatDate = (date: string) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    
    };
    const formattedExpiryDate = formatDate(expiryDate);
    var entity = {
      name: name,
      description:description,
      expiry_date: formattedExpiryDate,
      value: value,
      coupon_type_id: couponTypeId,
      is_active:is_active
    }
    this.getFormErrors(this.form);
   
    if (this.form.valid) {
      this.globals.isLoading = true;
      if (this.id == 0) {
        this.add(entity);
      } else {
        this.update(entity, this.id);
      }
    }
  }
  private getFormErrors(formGroup: FormGroup | FormArray, path: string = '') {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        // Recursive call for nested FormGroup
        this.getFormErrors(control, `${path}${key}.`);
      } else if (control instanceof FormArray) {
        // Handle FormArray if needed
        control.controls.forEach((subControl, index) => {
          this.getFormErrors(subControl as FormGroup, `${path}${key}[${index}].`);
        });
      } else {
        // Print errors for FormControl
        if (control?.errors) {
        }
      }
    });
  }
  add(entity) {

    entity.is_active = (entity.is_active)?1:0;
    this.couponService.add(entity)
      .then((data) => {
        this.globals.isLoading = false;
       

        this.globals.sweetAlert('success','Coupon Added','Coupon has been added successfully.');
        this.router.navigate(['/manage/coupon/list']);
      },
      (error) => {
        this.globals.isLoading = false;
        if(error.error.code == 303){
          this.globals.sweetAlert('error','Coupon Exists','Coupon Name has been already been taken.');
        }

          this.globals.errorSweetAlert();
        
      });
    }
  

  update(entity, id) {
    entity.is_active = (entity.is_active)?1:0;
    this.couponService.update(entity, id)
      .then((data) => {
        this.globals.isLoading = false;
        this.globals.sweetAlert('success','Coupon Updated','Coupon has been updated successfully.');
        this.router.navigate(['/manage/coupon/list']);
      },
      (error) => {
        this.globals.isLoading = false;
     
          this.globals.errorSweetAlert();
        
      });
  }

  get amntvalue() {
    return this.form.get('amntvalue');
  }

}
export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': 'value is only whitespace' };
  };
}

