<div class="auth_dashboard">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3">
                <div class="image_block">
                    <div class="inner_valign">
                        <img src="{{globals.cndURL}}assets/images/logo.png" alt="" class="img-fluid" />
                        <div class="footer_block"> Copyright &copy; <span class="footer_year">2021</span>, <a
                                href="https://www.ifma.org/" target="_blank">International Facility Management Association
                                (IFMA)</a>. No materials, including graphics, may be reused, modified, or
                                reproduced without written permission.<br>
                            Powered by <a href="https://www.theopeneyes.com/" target="_blank">OpenEyes Technologies
                                Inc.</a>
                           </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9">
                <div class="landing_height_block">
                    <app-header *ngIf="globals.authData!=null"></app-header>
                    <div class="banner_block"><img src="{{globals.cndURL}}assets/images/connector_bg1.jpg" alt="" class="img-fluid" /></div>
                    <div class="right_block">
                        <div class="inner_valign">
                                <div class="row justify-content-center" *ngIf="org_register">
                                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <h4>The Connector is an information and management tool for organization administrators. You can use this tool to view, add, change, and update information on employees, units, and divisions within the organization and create connections across multiple users.<br><br>
                                    To access this tool as Primary Contact, you must first <a routerLink="/organization-registration">register</a> your organization.<br><br>
                                    <!-- However, if you are not an administrator, you may contact an organization to apply for this position.  -->
                                </h4>
                                <h4 class="mb-5">You have two choices: </h4>
                                    </div>
                                    <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex">
                                        <div class="institute_block register_new_block color1 only_register_block">
                                                <div class="content_block">
                                                    <p>Contact an organization to apply to join their workforce as an administrator.</p>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="active_block">
                                                    <span>No action required!</span> </div>
                                        </div>
                                    </div>
                                <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex">
                                    <div class="institute_block register_new_block color2">
                                            <div class="content_block">
                                                <p>Register your own organization and become a Primary Contact.</p>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="active_block">
                                                <a routerLink="/organization-registration"  class="all_btn theme_btn">Register Now</a></div>
                                    </div>
                                </div>
                            </div>
                            <form class="k-form" [formGroup]="selectForm" *ngIf="!org_register">
                                <h4 class="text-center mb-5" *ngIf="(globals.authData.list.length!=institute_count)">Which role you would like to select? </h4>
                                <h4 class="text-center mb-5" *ngIf="(globals.authData.list.length==institute_count)">Select an organization </h4>
                                <div class="clearfix"></div>
                                <div class="role_radio_block">
                                    <div class="row justify-content-center">
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2"
                                            *ngIf="oess_present">
                                            <div class="role_radio">
                                                <input formControlName="role" type="radio" id="oess" value="1"
                                                    name="role" (click)="selectRole(1)">
                                                <label for="oess"><img src="{{globals.cndURL}}assets/images/oeti.png"
                                                        alt="" class="img-fluid" />
                                                    <span>OESS Administrator</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2"
                                            *ngIf="amsn_present">
                                            <div class="role_radio">
                                                <input formControlName="role" type="radio" id="amsn" value="2"
                                                    name="role" (click)="selectRole(2)">
                                                <label for="amsn"><img
                                                        src="{{globals.cndURL}}assets/images/amsn_small.png" alt=""
                                                        class="img-fluid" />
                                                    <span>IFMA Administrator</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2"
                                            *ngIf="institute_count>0 && globals.authData.list.length!=institute_count">
                                            <div class="role_radio">
                                                <input formControlName="role" type="radio" id="Institute" value="3"
                                                    name="role" (click)="selectRole(3)">
                                                <label for="Institute"><img
                                                        src="{{globals.cndURL}}assets/images/org.png" alt=""
                                                        class="img-fluid" />
                                                    <span>Organization</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2"
                                            *ngIf="individual_present">
                                            <div class="role_radio">
                                                <input formControlName="role" type="radio" id="Individual" value="5"
                                                    name="role" (click)="selectRole(5)">
                                                <label for="Individual"><img
                                                        src="{{globals.cndURL}}assets/images/indi.png" alt=""
                                                        class="img-fluid" />
                                                    <span>Individual</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="clearfix"></div>
                            <div class="auth_institute_dashboard" *ngIf="role==3">
                                <div class="row justify-content-center">
                                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="box_search_block">
                                            <p class="padding_30" *ngIf="instituteList.length>1">It seems you are administrating more than one organizations.
                                            <br>Please select an organization to administer or to view their dashboard!</p>
                                        <p class="padding_30" *ngIf="instituteList.length==1">It seems you are associated with one organization.</p>
                                        <div class="clearfix"></div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 d-flex">
                                        <div class="institute_block register_new_block color1">
                                           
                                                <div class="content_block">
                                                    <p>Register your own organization and become a Primary Contact.</p>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="active_block">
                                                    <a routerLink="/organization-registration" class="all_btn theme_btn">Register Now</a></div>
                                            
                                        </div>
                                    </div>
                                    <ng-container *ngFor="let insti of instituteList; let i=index;">
                                      
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 d-flex border_line"
                                            *ngIf="insti.institute!='' && insti.institute.is_active==1">
                                            <label class="institute_block" for="institute{{i}}">
                                                <input type="radio" id="institute{{i}}" name="role_select" value="{{i}}"
                                                    (click)="selectOrg(i)" kendoRadioButton />
                                                <span class="check_block"></span>
                                                <div class="content_block">
                                                    <h1>{{insti.institute.institute_name}}</h1>
                                                    <div *ngIf="insti.institute.provider_id" class="id_box">
                                                        {{insti.institute.provider_id}}</div>
                                                    <p><i class="fa fa-map-marker"></i>
                                                        <span>
                                                        
                                                            {{insti.institute.city}},
                                                          
                                                            {{insti.institute.states.state_abbreviation}}
                                                            {{insti.institute.zipcode}}
                                                        </span>
                                                    </p>
                                                
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="role_block">
                                                    Your Role: <span>{{insti.role_name}}
                                                    </span></div>
                                            </label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="text-center" *ngIf="!org_register">
                                <button kendoButton [disabled]="disableBtn" (click)="changeRole()" [primary]="true"
                                    class="all_btn theme_btn">Select</button>
                            </div>
                        </div>
                    </div>
                    <app-footer *ngIf="globals.authData!=null"></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>