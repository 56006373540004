import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
declare var $, Swal: any;
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  constructor(private AuthService: AuthService, private router: Router, public globals: Globals, private CookieService: CookieService,
  ) { }

  currentRole: any;

  ngOnInit(): void {
    this.globals.isLoading = true;
 
    const body = document.querySelector('body');
    body.style.setProperty('--screen-height', $(window).height() + "px");

    $(document).on("click", function (event) {
      var $trigger = $(".header_dropdown");
      if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".header_dropdown .dropdown-menu").removeClass("show");
        $(".header_dropdown").removeClass("show");
      }
      $(".header_dropdown .dropdown-menu a").on("click", function (event) {
        $(".header_dropdown .dropdown-menu").removeClass("show");
        $(".header_dropdown").removeClass("show");
      });
    });

    this.AuthService.getUserInsittuteData().then((data: any) => {
      let institute_details = data.filter(
        (s) => s.institute && s.institute.id == this.globals.selected_institute.id
      );

    
      let newRole = institute_details[0]?.role_value;
      let currentRole = this.globals.institute_details.role_value
    
      this.currentRole = institute_details[0]?.role_name;
      if(institute_details.length > 0){

        if (newRole !== currentRole) {
     
     
          // Store the new role in cookies
          if (this.globals.institute_details.role_value != 1 && this.globals.institute_details.role_value != 2) {
            this.CookieService.set(
              'institute_details',
              window.btoa(JSON.stringify(institute_details[0])),
              365,
              '/',
              this.globals.CookieDomainUrl
            );
          }
  
  
      
          this.CookieService.set(
            'selected_institute',
            window.btoa(JSON.stringify(institute_details[0].institute)),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
      
          
  
          const URL = newRole == 3 ? 'dashboard' : 'manage/assigned-user-subscription/list';
      
          // Check if redirect has already been done in this session
            this.globals.isLoading = true;
      
      
            setTimeout(() => {
              window.location.href = this.globals.baseUrl + '/' + URL;
              this.globals.isLoading = false;
            
             
            }, 1);
          
  
        
        }
      }
      else{
       
        if (this.globals.institute_details.role_value != 1 && this.globals.institute_details.role_value != 2) {
         

          this.CookieService.set(
            'institute_details',
            window.btoa(JSON.stringify(data[0])),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.CookieService.set(
            'selected_institute',
            window.btoa(JSON.stringify(data[0].institute)),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
  
          this.globals.selectedInstitute = data[0].institute

          const URL = data[0].role_value == 3 ? 'dashboard' : 'manage/assigned-user-subscription/list';


          setTimeout(() => {
            window.location.href = this.globals.baseUrl + '/' + URL;
            this.globals.isLoading = false;
          
           
          }, 1);
      
        }


    
      
    
         
      }
    
     
    }, (error) => {
    });
    


  }

  logout() {
    Swal.fire({
      icon: 'warning',
      title: 'Logout',
      text: 'Are you sure you want to logout?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.globals.isLoading = true;
          this.AuthService.logout()
            .then((data) => {
               
              window.location.href = this.globals.amsnLogoutUrl;
            },
              (error) => {
                window.location.href = this.globals.amsnLogoutUrl;
               
              });
        }
      })
  }
  dropdown_open() {
    $(".header_dropdown").toggleClass("show");
    $(".header_dropdown .dropdown-menu").toggleClass("show");
  }
}
